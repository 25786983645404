import React, { useState } from "react";
import { ThemeProvider } from "styled-components"; // Dodato ThemeProvider
import {
  BoxContent,
  BoxWrrapper,
  MainWrapper,
  PageWrapper,
  RightContent,
  BoxTitle,
  FormWrap,
  CheckTheme,
  CheckThemeContent,
} from "../../style/DasboardMain";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import DropdownSingle from "../../components/inputs/DropdownSingle";
import RadioButton from "../../components/inputs/RadioButton";

// Language
import { useTranslation } from "react-i18next";
import {
  GlobalStyles,
  lightTheme,
  darkTheme,
  getColor,
} from "../../style/Global"; // Uvezeno GlobalStyles, lightTheme, darkTheme, getColor
import { useLexicon } from "../../hooks/useLexicon";

const Index = () => {
  const { t, i18n } = useTranslation();
  const l = useLexicon();

  const languages = [
    { label: "English Language (UK)", value: "en" },
    { label: "Spanish", value: "es" },
  ];

  const [selectedOption, setSelectedOption] = useState(languages[0].label);
  const [selectedRadio, setSelectedRadio] = useState(
    localStorage.getItem("theme") || "dark"
  );

  const handleOptionChange = (optionValue) => {
    setSelectedOption(optionValue);
    const selectedLang = languages.find((item) => item.label === optionValue);
    i18n.changeLanguage(selectedLang.value);
    localStorage.setItem("lang", selectedLang.value);
  };

  const handleChange = (value) => {
    setSelectedRadio(value);
    localStorage.setItem("theme", value);

    window.location.reload();
  };

  return (
    <ThemeProvider theme={selectedRadio === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <MainWrapper>
          <Navigation />
          <RightContent>
            <IntroText
              smallTitle={t("setup.smallTitle")}
              title={l(977)}
            />
            <PageWrapper>
              <BoxWrrapper>
                <BoxContent>
                  <BoxTitle>
                    <h2>{l(978)}</h2>
                    <p>{l(979)}</p>
                  </BoxTitle>
                  <FormWrap style={{ margin: 0 }}>
                    {/* <DropdownSingle
                      title={t("setup.language")}
                      options={languages}
                      value={selectedOption}
                      onChange={handleOptionChange}
                    /> */}
                    <CheckTheme>
                      <span>{l(980)}</span>
                      <CheckThemeContent>
                        <RadioButton
                          label={l(981)}
                          value="dark"
                          checked={selectedRadio === "dark"}
                          onChange={handleChange}
                        />
                        <RadioButton
                          label={l(982)}
                          value="light"
                          checked={selectedRadio === "light"}
                          onChange={handleChange}
                        />
                      </CheckThemeContent>
                    </CheckTheme>
                  </FormWrap>
                </BoxContent>
              </BoxWrrapper>
            </PageWrapper>
          </RightContent>
        </MainWrapper>
      </>
    </ThemeProvider>
  );
};

export default Index;
