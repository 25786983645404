import React, { useState } from "react";

// Style
import {
  TableWrap,
  TableContent,
  ProfileImage,
  LeftContent,
  ImagesWrap,
  RightContent,
  SeeMoreWrap,
} from "../../style/FavoriteTable";

// Images
import star from "../../images/ic_start.svg";
import emptyState from "../../images/contactLogo.svg";
import sort from "../../images/ic_sort.svg";
import arrowDark from "../../images/arrow_dark.svg";
import arrowLight from "../../images/arrow_white.svg";
import compare from "../../images/ic_compare.svg";
import compareBlue from "../../images/compare_blue.svg";

// Components
import Empty from "../../components/empty/index";
import Modal from "../../components/modal/index";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { $apiV1 } from "../../config/api";
import { AlfabetSort } from "../../style/Playlist";
import { PageButton, PaginationWrapper } from "../../style/Favorites";
import { Pagination } from "../ui";
import { Compare } from "../../style/SmrtSearch";
import { Tooltip } from "react-tooltip";
import Button from "../button";
import { useLexicon } from "../../hooks/useLexicon";

const FavoritesTable = ({
  table,
  setFavList,
  sortOrder,
  onSortChange,
  page,
  totalPages,
  onPageChange,
  totalPlayers,
}) => {
  const { t, i18n } = useTranslation();
  const l = useLexicon();
  const navigate = useNavigate();

  const [successPopup, setSuccessPopup] = useState(false);

  const handleRemoveFromFavorites = async (item) => {
    try {
      await $apiV1.delete(`/users/favorites/players/${item?.player_id}/`);
      window.location.reload();
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const handleCompare = (item) => {
    // Retrieve the players array from local storage
    const storedPlayers = localStorage.getItem("players");
    let playersArray = [];

    if (storedPlayers) {
      // Parse the existing players array from local storage
      playersArray = JSON.parse(storedPlayers);
    }

    // Convert id to number (assuming id is initially a string)
    const playerId = parseInt(item.player_id, 10);

    // Check if the item ID is already in the array
    if (!playersArray.includes(playerId)) {
      // Add the new player ID to the array
      playersArray.push(playerId);
      // Store the updated array back in local storage
      localStorage.setItem("players", JSON.stringify(playersArray));
    }

    setSuccessPopup(!successPopup);
  };

  const modal = (
    <>
      {/* Success added to compare  */}
      <Modal isOpen={successPopup} setIsOpen={setSuccessPopup} closeIc>
        <h1>{l(1046)}</h1>
        <br />
        <Button
          title={l(1047)}
          size={"full"}
          onClick={() => navigate(`/compare`)}
        />
      </Modal>
    </>
  );

  return (
    <>
      <AlfabetSort
        onClick={onSortChange}
        style={{ cursor: "pointer", marginBottom: "10px" }}
      >
        <h6>{l(962)}</h6>
        <img src={sort} alt="sort" />
      </AlfabetSort>
      {table?.length > 0 ? (
        <>
          <TableWrap>
            {table.map((item, index) => {
              return (
                <TableContent key={index}>
                  <LeftContent style={{ color: "#fff" }}>
                    <ImagesWrap>
                      <img
                        src={star}
                        alt={star}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveFromFavorites(item)}
                      />
                      <ProfileImage
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/tournaments/player/${item.player_id}`)
                        }
                        onContextMenu={() =>
                          window.open(
                            `/tournaments/player/${item.player_id}`,
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={
                            item?.player?.photos.length > 0
                              ? `https://platform.smrtstats.com:8888/${item?.player?.photos[0]?.image}`
                              : emptyState
                          }
                          alt={emptyState}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                          }}
                        />
                        <span>
                          <img src={item.flag} alt={item.flag} />
                        </span>
                      </ProfileImage>
                    </ImagesWrap>
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/tournaments/player/${item.player_id}`)
                      }
                      onContextMenu={() =>
                        window.open(
                          `/tournaments/player/${item.player_id}`,
                          "_blank"
                        )
                      }
                    >
                      {item?.player?.display_name}
                    </h3>
                  </LeftContent>
                  <RightContent  style={{display: 'flex'}}>
                    <SeeMoreWrap
                      onClick={() =>
                        navigate(`/tournaments/player/${item.player_id}`)
                      }
                      onContextMenu={() =>
                        window.open(
                          `/tournaments/player/${item.player_id}`,
                          "_blank"
                        )
                      }
                    >
                      {l(963)}
                    </SeeMoreWrap>
                    <Compare onClick={() => handleCompare(item)} data-tooltip-id={`compare-${item.player_id}`}>
                      {" "}
                      <img
                        src={
                          localStorage.getItem("theme") === "light"
                            ? compareBlue
                            : compare
                        }
                        alt={compare}
                      />
                      <Tooltip
                        id={`compare-${item.player_id}`}
                        place="bottom"
                        content={l(1051)}
                      />
                    </Compare>
                  </RightContent>
                </TableContent>
              );
            })}
          </TableWrap>
          <Pagination
            styleWrapper={{ marginTop: 10 }}
            items={totalPlayers}
            itemsPerPage={10}
            page={page - 1 || 0}
            onPageChange={handlePageChange}
          />
          {/* <PaginationWrapper>
            <PageButton onClick={() => handlePageChange(page - 1)}>
              <img
                src={
                  localStorage.getItem("theme") === "light"
                    ? arrowLight
                    : arrowDark
                }
                alt={arrowDark}
                className="leftTransform"
              />
            </PageButton>
            <span>
              {page} of {totalPages.toLocaleString()}
            </span>
            <PageButton onClick={() => handlePageChange(page + 1)}>
              <img
                className="rightTransform"
                src={
                  localStorage.getItem("theme") === "light"
                    ? arrowLight
                    : arrowDark
                }
                alt={arrowDark}
              />
            </PageButton>
          </PaginationWrapper> */}
        </>
      ) : (
        <Empty
          title={l(447)}
          text={l(1052)}
          button1={l(1053)}
          button2={l(1054)}
        />
      )}
      {modal}
    </>
  );
};

export default FavoritesTable;
