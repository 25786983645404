import React, { useEffect, useMemo, useState } from "react";
import { Oval } from "react-loader-spinner";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Language
import { useTranslation } from "react-i18next";
import {
  BottomCardsWrap,
  Cards,
  CardsWrap,
  InnerContent,
  LoadingState,
  OverviewCard,
  OverviewWrap,
  StatsCard,
  TopCardInfo,
  TopCardsWrap,
  VideoContent,
  VideoInfo,
  VideoItems,
  VideoList,
  VideoStats,
  WrappBottomContent,
  WrappContent,
  LoadingVideo,
} from "../../style/Overview";

// Images
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import play from "../../images/ic_play-button.svg";
import playBlue from "../../images/play_blue.svg";
import { useNavigate } from "react-router-dom";
import { EmptyCard } from "../../style/Global";
import { getPlayerParamMarkers, getTeamParamMarkers } from "../../helpers";
import { NotificationList } from "../notification/NotificationList";
import { useNotification } from "../notification/useNotification";
import Matches from "./Matches";
import { DropdownWrapper, OpenedDropdown } from "./MatchTable";
import TextInput from "../inputs/TextInput";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useLexicon } from "../../hooks/useLexicon";

const Overview = ({
  table,
  leaders,
  loadingList,
  id,
  handleSelectChange,
  selectedSeason,
  statMatch,
  setStatMatch,
  teamInfo,
  loadingStatMatch
}) => {
  const l = useLexicon();
  const [openChangeSeason, setOpenChangeSeason] = useState(false);
  const [expandedIndices, setExpandedIndices] = useState([]);

  const currentSelectedSeason = useMemo(() => {
    if (table?.available_seasons?.length > 0) {
      const foundedSeason = table?.available_seasons?.find((item) => item.id == selectedSeason);
      if (selectedSeason && foundedSeason) {
        return foundedSeason?.years;
      } else {
        return table?.available_seasons?.[0]?.years;
      }
    }
    return l(992) || "Select Season";
  }, [selectedSeason, table?.available_seasons, l]);

  const navigate = useNavigate();

  const { notifications, addNotification, removeNotification } = useNotification();

  const toggleShowLeads = (index) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  //new params
  // first green section
  const ShotsOnTarget = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 24);
      return value;
    }
    return null
  }, [leaders])

  const ShotsOnTargetWide = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 26);
      return value;
    }
    return null
  }, [leaders])

  const CounterAttacks = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 196);
      return value;
    }
    return null
  }, [leaders])

  const Corners = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 146);
      return value;
    }
    return null
  }, [leaders])

  //second green section
  const FreeKicks = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 147);
      return value;
    }
    return null
  }, [leaders])

  const Penalties = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 190);
      return value;
    }
    return null
  }, [leaders])

  const Crosses = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 49);
      return value;
    }
    return null
  }, [leaders])

  const Dribblings = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 90);
      return value;
    }
    return null
  }, [leaders])

  //first red section
  const OppCounterAttacks = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 196);
      return value;
    }
    return null
  }, [leaders])

  const OppCorners = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 146);
      return value;
    }
    return null
  }, [leaders])

  const OppFreeKicks = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 147);
      return value;
    }
    return null
  }, [leaders])

  const OppPenalties = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 190);
      return value;
    }
    return null
  }, [leaders])

  //second red section
  const graveMistakes = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 143);
      return value;
    }
    return null
  }, [leaders])

  const arialDuels = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 105);
      return value;
    }
    return null
  }, [leaders])

  const ballInterception = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 130);
      return value;
    }
    return null
  }, [leaders])

  const ballRecoveries = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 136);
      return value;
    }
    return null
  }, [leaders])

  // new params

  const GoalsScored = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 1);
      return value;
    }
    return null
  }, [leaders])

  const GoalOpportunities = useMemo(() => {
    if (leaders?.in_attack?.length > 0) {
      const value = leaders?.in_attack?.find(item => item.param_id === 4);
      const value2 = leaders?.in_attack?.find(item => item.param_id === 6);
      let result = value;
      if (value2) {
        result = { markers: [...value.markers, value2.markers], count: value.count + value2.count }
      }
      return result
    }
    return null
  }, [leaders])

  const OppGoalOpportunities = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 6);
      return value;
    }
    return null
  }, [leaders])


  const GoalsConceeded = useMemo(() => {
    if (leaders?.in_defence?.length > 0) {
      const value = leaders?.in_defence?.find(item => item.param_id === 2);
      return value;
    }
    return null
  }, [leaders])

  // const GoalOppSum = useMemo(() => {
  //   return (GoalsUnsuccessfull?.count || 0) + (GoalsScored?.count || 0)
  // }, [GoalsUnsuccessfull, GoalsScored])

  const handleClickPlayerParam = async (param, player) => {
    const { parameter_id: param_id, parameter_name } = param;
    const { player: { id: player_id, display_name }, count } = player;
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add('loading-cursor');
      const data = await getPlayerParamMarkers(player_id, param_id, selectedSeason, controller);
      if (data?.results?.length > 0) {
        const markers = data.results.map(item => item.id).join(',');
        window.open(`/tournaments/match/video?title=${parameter_name} - ${display_name}&markers=${markers}`)
      } else {
        addNotification(`There are 0 clips in the response from the server.`, 'warning')
      }
    } catch (e) {
      console.log('error get param markers', e);
      if (e.type === 'canceled') {
        addNotification(`The request to receive ${count} clips of the player has been canceled.`, 'warning')
        return
      }
      addNotification(`Error in receiving ${count} player clips.`, 'error')
    } finally {
      document.body.classList.remove('loading-cursor');
    }
  }

  const handleClickTeamParam = async (param) => {
    const { id: param_id, name, count } = param;
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add('loading-cursor');
      const data = await getTeamParamMarkers(id, param_id, 'in_attack', selectedSeason, controller);
      if (data?.results?.length > 0) {
        const markers = data.results.map(item => item.id).join(',');
        window.open(`/tournaments/match/video?title=${name} - ${table?.team?.name}&markers=${markers}`)
      } else {
        addNotification(`There are 0 clips in the response from the server.`, 'warning')
      }
    } catch (e) {
      console.log('error get param markers', e);
      if (e.type === 'canceled') {
        addNotification(`The request to receive ${count} clips of the team has been canceled.`, 'warning')
        return
      }
      addNotification(`Error in receiving team events. ${e.message}`, 'error')
    } finally {
      document.body.classList.remove('loading-cursor');
    }
  }

  const isCanClickPlayerStat = (leader, item) => {
    return leader?.count === 0
      || leader?.markers?.length === 0
      || item.parameter_name.includes('%')
      || item.parameter_name.includes('Mins Played')
  }

  const isCanClickTeamStat = (item) => {
    return item.count === 0
      || item?.markers?.length === 0
      || item.name.includes('%')
      || item.name.includes('Mins Played')
      || item.name.includes('Passes')
  }

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const changeSeasonDropdownRef = useOutsideClick(() => {
    if (openChangeSeason) {
      setOpenChangeSeason(false);
    }
  });


  return (
    <OverviewWrap>
      <TopCardsWrap>
        <WrappContent>
          <TopCardInfo>
            <h2>{l(8)}</h2>
            {/* <SelectDropdown>
              <select onChange={handleSelectChange} value={selectedSeason}>
                {table.available_seasons?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      Season {item.years}
                    </option>
                  );
                })}
              </select>
            </SelectDropdown> */}
          </TopCardInfo>
          <OverviewCard>
            {loadingList ? (
              <LoadingState style={{ margin: "20px" }}>
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  secondaryColor={
                    localStorage.getItem("theme") === "light"
                      ? "#4E5255"
                      : "#CFDAE6"
                  }
                  color={
                    localStorage.getItem("theme") === "light"
                      ? "#2363F7"
                      : "#4fa94d"
                  }
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </LoadingState>
            ) : (
              <InnerContent>
                {leaders && Object.keys(leaders.main_stats).length > 0 ? (
                  <>
                    {leaders.main_stats.map((item, index) => {
                      if (item.id === 144 || item.id === 36) return // Remove grave mistakes always 0
                      return (
                        <div key={index} className="statistics statsPadding">
                          <h5>{item.name}:</h5>
                          <h4
                            style={
                              isCanClickTeamStat(item)
                                ? { cursor: "not-allowed" }
                                : { cursor: "pointer" }
                            }
                            onClick={() => {
                              if (!isCanClickTeamStat(item)) {
                                const markers = item.markers?.slice(0, 500)?.join(',');
                                window.open(`/tournaments/match/video?title=${item.name} - ${table?.team?.name}&markers=${markers}`)
                              }
                            }

                            }
                          >
                            {item.count ? formatNumber(item.count) : item.count}
                          </h4>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <EmptyCard>
                    <h2>{l(464)}</h2>
                  </EmptyCard>
                )}
              </InnerContent>
            )}
          </OverviewCard>
        </WrappContent>
        {loadingStatMatch ? (
          <LoadingState>
            <Oval
              visible={true}
              height="80"
              width="80"
              secondaryColor={
                localStorage.getItem("theme") === "light"
                  ? "#4E5255"
                  : "#CFDAE6"
              }
              color={
                localStorage.getItem("theme") === "light"
                  ? "#2363F7"
                  : "#4fa94d"
              }
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </LoadingState>
        ) : (
          <Matches
            table={statMatch?.matches_list || []}
            onChangeData={(data, season) => {
              setStatMatch(data)
            }}
            type={'team'}
            styleTableWrapper={{
              height: '300px',
              overflowY: 'scroll',
              scrollbarWidth: 'auto',
            }}
            showTitle={true}
            season={selectedSeason}
            playerTeams={table?.team ? [table.team] : []}
          />
        )}
      </TopCardsWrap>
      <>
        {
          loadingList ? (
            <LoadingState>
              <Oval
                visible={true}
                height="80"
                width="80"
                secondaryColor={
                  localStorage.getItem("theme") === "light"
                    ? "#4E5255"
                    : "#CFDAE6"
                }
                color={
                  localStorage.getItem("theme") === "light"
                    ? "#2363F7"
                    : "#4fa94d"
                }
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </LoadingState>
          ) : (
            <WrappContent style={{ marginBottom: 30 }}>
              <TopCardInfo>
                <h2>{l(1060)}</h2>
                {/* <SelectDropdown>
                  <select onChange={handleSelectChange} value={selectedSeason}>
                    {table.available_seasons?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          Season {item.years}
                        </option>
                      );
                    })}
                  </select>
                </SelectDropdown> */}
                <DropdownWrapper ref={changeSeasonDropdownRef}>
                  <div
                    style={{ position: "relative" }}
                    onClick={() => setOpenChangeSeason(!openChangeSeason)}
                  >
                    <TextInput
                      type="text"
                      value={currentSelectedSeason}
                      readOnly
                    />

                    <img
                      src={openChangeSeason ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  {openChangeSeason && (
                    <OpenedDropdown>
                      {table?.available_seasons?.map((item) => (
                        <div key={item.id} onClick={() => handleSelectChange({ target: { value: item.id } })}>
                          {item.years}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>
              </TopCardInfo>
              <OverviewCard style={{ height: 400 }}>
                <InnerContent>
                  <CardsWrap>
                    {leaders?.team_leaders.length > 0 ? (
                      leaders?.team_leaders?.filter(item => item.parameter_id !== 111 && item.parameter_id !== 153 && item.parameter_id !== 176 && item.parameter_id !== 181 && item.parameter_id !== 182)?.sort((a, b) => a.parameter_id - b.parameter_id).map((item, index) => (
                        <Cards key={index}>
                          <h3 title={item?.parameter_name}>{item?.parameter_name}</h3>
                          {item?.leaders.slice(0, 5).map((leader, idx) => (
                            <div key={idx} className="statistics">
                              <h5
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(
                                    `/tournaments/player/${leader?.player?.id}`
                                  )
                                }
                              >
                                {leader?.player?.display_name}:
                              </h5>
                              <h4
                                style={
                                  isCanClickPlayerStat(leader, item)
                                    ? { cursor: "not-allowed" }
                                    : { cursor: "pointer" }
                                }
                                onClick={() => {
                                  if (!isCanClickPlayerStat(leader, item)) {
                                    const markers = leader?.markers?.join(",");
                                    window.open(`/tournaments/match/video?title=${item?.parameter_name} - ${leader?.player?.display_name}&markers=${markers}`);
                                  }
                                }
                                }
                              >
                                {leader?.count}
                              </h4>
                            </div>
                          ))}
                          {item?.leaders?.length >= 5 && (
                            <button onClick={() => toggleShowLeads(index)}>
                              {expandedIndices.includes(index)
                                ? "Show less"
                                : "Show more"}
                            </button>
                          )}
                          {expandedIndices.includes(index) && (
                            <>
                              {item?.leaders.slice(5).map((leader, idx) => (
                                <div key={idx} className="statistics">
                                  <h5
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate(
                                        `/tournaments/player/${leader?.player?.id}`
                                      )
                                    }
                                  >
                                    {leader?.player?.display_name}:
                                  </h5>
                                  <h4
                                    style={
                                      isCanClickPlayerStat(leader, item)
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() =>
                                      !isCanClickPlayerStat(leader, item) &&
                                      handleClickPlayerParam(item, leader)
                                    }
                                  >
                                    {leader?.count}
                                  </h4>
                                </div>
                              ))}
                            </>
                          )}
                        </Cards>
                      ))
                    ) : (
                      <EmptyCard>
                        <h2>{l(464)}</h2>
                      </EmptyCard>
                    )}
                  </CardsWrap>
                </InnerContent>
              </OverviewCard>
            </WrappContent>
          )
        }
      </>


      <BottomCardsWrap>
        <WrappBottomContent>
          <TopCardInfo>
            <h2>{table?.team?.name} {l(989)}</h2>
          </TopCardInfo>
          <OverviewCard
            style={{ height: "fit-content", scrollbarWidth: "none" }}
          >
            <InnerContent>
              <VideoContent>
                <VideoStats>
                  <StatsCard
                    onClick={() => {
                      GoalsScored?.markers?.length > 0 && navigate(
                        `/tournaments/match/video?title=${l(102)} - ${table?.team?.name
                        }&markers=${GoalsScored?.markers?.join(",")}`
                      )
                    }}
                  >
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {GoalsScored?.count || 0}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#36e82d",
                      }}
                    >
                      {l(102)}
                    </h5>
                    <img
                      src={
                        localStorage.getItem("theme") === "light"
                          ? playBlue
                          : play
                      }
                      width={40}
                      alt={play}
                      style={{ cursor: "pointer", position: 'absolute', right: 20, top: '10%' }}
                    />
                  </StatsCard>

                  {loadingList ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {ShotsOnTarget && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(462)} - ${table?.team?.name
                              }&markers=${ShotsOnTarget?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(462)}</h3>{" "}
                            <span>{ShotsOnTarget.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {ShotsOnTargetWide && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(570)} - ${table?.team?.name
                              }&markers=${ShotsOnTargetWide?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(570)}</h3>{" "}
                            <span>{ShotsOnTargetWide.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {CounterAttacks && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(760)} - ${table?.team?.name
                              }&markers=${CounterAttacks?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(760)}</h3>{" "}
                            <span>{CounterAttacks.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {Corners && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(461)} - ${table?.team?.name
                              }&markers=${Corners?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(461)}</h3>{" "}
                            <span>{Corners.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                    </VideoList>
                  )}
                </VideoStats>

                <VideoStats>
                  <StatsCard
                    onClick={() => {
                      GoalOpportunities?.markers?.length > 0 && navigate(
                        `/tournaments/match/video?title=${l(429)} - ${table?.team?.name
                        }&markers=${GoalOpportunities?.markers?.join(",")}`
                      )
                    }}
                  >
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {GoalOpportunities?.count}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#36e82d",
                      }}
                    >
                      {l(429)}
                    </h5>
                    <img
                      src={
                        localStorage.getItem("theme") === "light"
                          ? playBlue
                          : play
                      }
                      width={40}
                      alt={play}
                      style={{ cursor: "pointer", position: 'absolute', right: 20, top: '10%' }}
                    />
                  </StatsCard>

                  {loadingList ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {FreeKicks && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(731)} - ${table?.team?.name
                              }&markers=${FreeKicks?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(731)}</h3>{" "}
                            <span>{FreeKicks.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {Penalties && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(756)} - ${table?.team?.name
                              }&markers=${Penalties?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(756)}</h3>{" "}
                            <span>{Penalties.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {Crosses && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(587)} - ${table?.team?.name
                              }&markers=${Crosses?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(587)}</h3>{" "}
                            <span>{Crosses.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {Dribblings && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(63)} - ${table?.team?.name
                              }&markers=${Dribblings?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(63)}</h3>{" "}
                            <span>{Dribblings.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                    </VideoList>
                  )}
                </VideoStats>
              </VideoContent>
            </InnerContent>
          </OverviewCard>
        </WrappBottomContent>

        <WrappBottomContent>
          <TopCardInfo>
            <h2>{table?.team?.name} {l(990)}</h2>
          </TopCardInfo>
          <OverviewCard
            style={{ height: "fit-content", scrollbarWidth: "none" }}
          >
            <InnerContent>
              <VideoContent>
                <VideoStats>
                  <StatsCard
                    className="defense"
                    onClick={() => {
                      GoalsConceeded?.markers?.length > 0 && navigate(
                        `/tournaments/match/video?title=${l(318)} - ${table?.team?.name
                        }&markers=${GoalsConceeded?.markers?.join(",")}`
                      )
                    }}
                  >
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {GoalsConceeded?.count || 0}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#F20A46",
                      }}
                    >
                      {l(318)}
                    </h5>
                    <img
                      src={
                        localStorage.getItem("theme") === "light"
                          ? playBlue
                          : play
                      }
                      width={40}
                      alt={play}
                      style={{ cursor: "pointer", position: 'absolute', right: 20, top: '10%' }}
                    />
                  </StatsCard>

                  {loadingList ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {OppCounterAttacks && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(1062)} - ${table?.team?.name
                              }&markers=${OppCounterAttacks?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(1062)}</h3>{" "}
                            <span>{OppCounterAttacks.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {OppCorners && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=Opponent's Corners - ${table?.team?.name
                              }&markers=${OppCorners?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>Opponent's Corners</h3>{" "}
                            <span>{OppCorners.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {OppFreeKicks && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(1063)} - ${table?.team?.name
                              }&markers=${OppFreeKicks?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(1063)}</h3>{" "}
                            <span>{OppFreeKicks.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {OppPenalties && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(1064)} - ${table?.team?.name
                              }&markers=${OppPenalties?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(1064)}</h3>{" "}
                            <span>{OppPenalties.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                    </VideoList>
                  )}
                </VideoStats>

                <VideoStats>
                  <StatsCard
                    className="defense"
                    onClick={() => {
                      OppGoalOpportunities?.markers?.length > 0 && navigate(
                        `/tournaments/match/video?title=${l(1065)} - ${table?.team?.name
                        }&markers=${OppGoalOpportunities?.markers?.join(",")}`
                      )
                    }}
                  >
                    <h4
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#fff",
                      }}
                    >
                      {OppGoalOpportunities?.count || 0}
                    </h4>
                    <h5
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#015ead"
                            : "#F20A46",
                      }}
                    >
                      {l(1065)}
                    </h5>
                    <img
                      src={
                        localStorage.getItem("theme") === "light"
                          ? playBlue
                          : play
                      }
                      width={40}
                      alt={play}
                      style={{ cursor: "pointer", position: 'absolute', right: 20, top: '10%' }}
                    />
                  </StatsCard>

                  {loadingList ? (
                    <LoadingVideo>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        secondaryColor={
                          localStorage.getItem("theme") === "light"
                            ? "#4E5255"
                            : "#CFDAE6"
                        }
                        color={
                          localStorage.getItem("theme") === "light"
                            ? "#2363F7"
                            : "#4fa94d"
                        }
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </LoadingVideo>
                  ) : (
                    <VideoList>
                      {graveMistakes && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(556)} - ${table?.team?.name
                              }&markers=${graveMistakes?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(556)}</h3>{" "}
                            <span>{graveMistakes.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {arialDuels && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(331)} - ${table?.team?.name
                              }&markers=${arialDuels?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(331)}</h3>{" "}
                            <span>{arialDuels.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {ballInterception && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(335)} - ${table?.team?.name
                              }&markers=${ballInterception?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(335)}</h3>{" "}
                            <span>{ballInterception.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                      {ballRecoveries && (
                        <VideoItems
                          onClick={() =>
                            navigate(
                              `/tournaments/match/video?title=${l(337)} - ${table?.team?.name
                              }&markers=${ballRecoveries?.markers?.join(",")}`
                            )
                          }
                        >
                          <img
                            src={
                              localStorage.getItem("theme") === "light"
                                ? playBlue
                                : play
                            }
                            alt={play}
                            style={{ cursor: "pointer" }}
                          />

                          <VideoInfo>
                            <h3>{l(337)}</h3>{" "}
                            <span>{ballRecoveries.count} {l(1061)}</span>
                          </VideoInfo>
                        </VideoItems>
                      )}
                    </VideoList>
                  )}
                </VideoStats>
              </VideoContent>
            </InnerContent>
          </OverviewCard>
        </WrappBottomContent>
      </BottomCardsWrap>

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </OverviewWrap >
  );
};

export default Overview;
