import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { Oval } from "react-loader-spinner";
import html2canvas from "html2canvas";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";

// Components
import Navigation from "../../../components/Navigation/Navigation";
import IntroText from "../../../components/introText/index";
import Search from "../../../components/inputs/Search";
import Breadcrumbs from "../../../components/breadcrumbs";
import Modal from "../../../components/modal/index";

// Images
import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";
import emptyState from "../../../images/contactLogo.svg";
import starOn from "../../../images/star_on.svg";
import starOff from "../../../images/star_off.svg";

// Language
import { useTranslation } from "react-i18next";
import {
  AvatarWithCountryContainer,
  BottomContent,
  Info,
  Stat,
  TeamCardWrap,
  TeamContent,
  TeamLeftInfo,
  TeamRightInfo,
  TeamStatistics,
  TopContent,
} from "../team/Index";
import { useNavigate, useParams } from "react-router-dom";
import ButtonLink from "../../../components/link/ButtonLink";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../../style/Favorites";
import Matches from "../../../components/table/Matches";
import Button from "../../../components/button";
import styled from "styled-components";
import {
  InnerContent,
  OverviewCard,
  TopCardInfo,
  WrappContent,
} from "../../../style/Overview";
import { breakpoints } from "../../../style/size";
import PlayerStat from "../../../components/table/PlayerStat";
import { LoadingWrapper, SelectDropdown } from "../../../style/Global";
import EventsPlayer from "./EventsPlayer";
import ShotsPlayer from "./ShotsPlayer";
import { $apiV1, $apiV2 } from "../../../config/api";
import { playerGkEventsParams } from "../../../config/parametrs/events/playerGkEventsParams";
import { playerEventsParams } from "../../../config/parametrs/events/playerEventsParams";
import CrossesPlayer from "./CrossesPlayer";
import { useNotification } from "../../../components/notification/useNotification";
import { getPlayerParamMarkers } from "../../../helpers";
import { NotificationList } from "../../../components/notification/NotificationList";
import axios from "axios";
import { DropdownWrapper, OpenedDropdown } from "../../../components/table/MatchTable";
import TextInput from "../../../components/inputs/TextInput";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useLexicon } from "../../../hooks/useLexicon";

export const TeamButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
  }
`;

const Index = () => {
  const l = useLexicon();
  const { t } = useTranslation();
  const [players, setPlayers] = useState(null);
  const [getPlayerInfoStat, setGetPlayerInfoStat] = useState([]);
  const [playerMatches, setPlayerMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [open, setOpen] = useState(false);
  const [matchStatsNumbers, setMatchStatsNumbers] = useState([]);

  const [paramsShotList, setParamsShotList] = useState([]);
  const [paramsCrossesList, setParamsCrossesList] = useState([]);

  const [seasonId, setSeasonId] = useState("");
  const [openChangeSeason, setOpenChangeSeason] = useState(false);

  const { notifications, addNotification, removeNotification } =
    useNotification();

  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedSeason, setSelectedSeason] = useState(
    sessionStorage.getItem("season_player")
      ? JSON.parse(sessionStorage.getItem("season_player"))
      : ""
  );

  const currentSelectedSeason = useMemo(() => {
    if (players?.available_seasons?.length > 0) {
      const foundedSeason = players?.available_seasons?.find((item) => item.id == selectedSeason);
      if (selectedSeason && foundedSeason) {
        return foundedSeason?.years;
      } else {
        return players?.available_seasons?.[0]?.years;
      }
    }
    return "Select Season";
  }, [selectedSeason, players?.available_seasons]);

  const paramsList = useMemo(() => {
    const isGk = players?.player?.position?.[0]?.id === 10; // id gooalkeeper position
    if (isGk) {
      return playerGkEventsParams;
    }
    return playerEventsParams;
  }, [players]);

  const handleSelectChange = (event) => {
    const selectedSeasonId = event.target.value;
    sessionStorage.setItem("season_player", JSON.stringify(selectedSeasonId));
    setSelectedSeason(selectedSeasonId);
    setOpenChangeSeason(false);
  };

  useEffect(() => {
    if (selectedSeason) {
      const selectedItem = players?.available_seasons?.find(
        (item) => item.id === selectedSeason
      );
    }
  }, [selectedSeason, players?.available_seasons]);

  const getPlayers = async (id) => {
    const season = selectedSeason;
    //
    try {
      setLoading(true);
      const response = await $apiV1.get(
        `/statistics/player/${id}?year_season_id=${seasonId || season}`
      );
      setPlayers(response?.data);
      setIsFavorite(Boolean(response?.data?.in_favorites));
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const getPlayerMatches = async (id) => {
    try {
      setLoading(true);
      const response = await $apiV1.get(`/statistics/player_matches/${id}`);
      setPlayerMatches(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const matchesId = playerMatches?.matches?.map((item) => item.id);

  const getPlayerMatchesStas = async (ids) => {
    try {
      const response = await $apiV1.get(`/stats/player/${id}`, {
        params: {
          matches: ids?.join(',') || matchesId?.join(","),
        },
      });
      setMatchStatsNumbers(response.data.stats);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getPlayerNew = async (id, season, params = [], downloadType) => {
    try {
      // setLoading(true);
      let currentParams = params;
      if (sessionStorage.getItem("playerParameters") && params?.length === 0) {
        const params = JSON.parse(sessionStorage.getItem("playerParameters"));
        const checkedIds = params.flatMap((group) =>
          group[1].filter((item) => item.checked).map((item) => item.id)
        );
        currentParams = checkedIds;
      }
      if (downloadType) {
        const file = await $apiV2.get(
          `/platform/player/${id}/matches?year_season_id=${season || selectedSeason
          }&params_ids=${currentParams.join(",")}&format_data=${downloadType}`
        );
        if (downloadType === 'xml') {
          fetch(file.data.file_url)
            .then(response => {
              if (!response.ok) throw new Error('Network response was not ok');
              return response.blob();
            })
            .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = file.data.file_url.split("/")[
                file.data.file_url.split("/").length - 1
              ];
              a.style.display = 'none';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            });
          return
        } else {
          const a = document.createElement("a");
          a.href = file.data.file_url;
          a.download =
            file.data.file_url.split("/")[
            file.data.file_url.split("/").length - 1
            ];
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          return
        }
      } else {
        const response = await $apiV2.get(
          `/platform/player/${id}/matches?year_season_id=${season || selectedSeason
          }&params_ids=${currentParams.join(",")}`
        );
        setGetPlayerInfoStat(response?.data);
      }
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        await $apiV1.delete(`/users/favorites/players/${id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: id });
      }
      setIsFavorite((prev) => !prev);
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  // const getParams = async () => {
  //   try {
  //     const response = await $apiV1.get(`/stats/parameters/player/`);
  //     setParamsList(response?.data.results);
  //   } catch (error) {
  //     console.error("Error fetching players:", error);
  //   } finally {
  //   }
  // };

  const getShotParams = async () => {
    try {
      const response = await $apiV1.get(`/stats/parameters/player/?group=2`);
      setParamsShotList(response?.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getCrossesParams = async () => {
    try {
      const response = await $apiV1.get(`/stats/parameters/player/?group=3`);
      setParamsCrossesList(response?.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const [userDetail, setUserDetail] = useState("");

  const getUser = async () => {
    try {
      const response = await $apiV1.get(`/users/user_profile/`);
      setUserDetail(response?.data?.user);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPlayers(id);
    getPlayerMatches(id);
    // getPlayerMatchesStas();
    getPlayerNew(id);
    // getParams();
    getShotParams();
    getCrossesParams();
    getUser();
  }, [id, selectedSeason]);

  const breadcrumbs = [
    {
      title: l(1),
      link: "/tournaments",
    },
    {
      title: `${players?.player?.team?.name}`,
      link: `/tournaments/team/${players?.player?.team?.id}`,
    },
    {
      title: `${players?.player?.display_name}`,
    },
  ];

  const [playerInfo, setPlayerInfo] = useState();
  const handleInitiate = (players) => {
    setPlayerInfo(players?.player);
    setOpen(!open);
  };

  const handleSendEmail = (playerInfo) => {
    const subject = encodeURIComponent(playerInfo.display_name);
    const mailtoLink = `mailto:sales@smrtstats.com?subject=${subject}`;
    window.location.href = mailtoLink;
  };

  const handleScreenShot = () => {
    const body = document.body;

    html2canvas(body)
      .then((canvas) => {
        // Convert canvas to image
        const screenshot = canvas.toDataURL("image/png");

        // Create an "a" element to trigger download
        const link = document.createElement("a");
        link.href = screenshot;
        link.download = "screenshot.png";
        link.click();
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);
      });
  };

  const handleClickPlayerParam = async (param, player_id) => {
    const { parameter_id: param_id, count, parameter_name } = param;
    try {
      const controller = new AbortController();
      addNotification(`Fetching clips`, "info", controller);
      document.body.classList.add("loading-cursor");
      const data = await getPlayerParamMarkers(
        player_id,
        param_id,
        selectedSeason,
        controller
      );
      if (data?.results?.length > 0) {
        const markers = data.results.map((item) => item.id).join(",");
        window.open(`/tournaments/match/video?title=${parameter_name} - ${players?.player?.display_name}&markers=${markers}`);
      } else {
        addNotification(
          `There are 0 clips in the response from the server.`,
          "warning"
        );
      }
    } catch (e) {
      console.log("error get param markers", e);
      if (e.type === "canceled") {
        addNotification(
          `The request to receive ${count} clips of the player has been canceled.`,
          "warning"
        );
        return;
      }
      addNotification(`Error in receiving ${count} player clips.`, "error");
    } finally {
      document.body.classList.remove("loading-cursor");
    }
  };

  const isCanClickPlayerStat = (parameter) => {
    return (
      parameter?.count === 0 ||
      parameter?.markers?.length === 0 ||
      parameter.parameter_name.includes("%") ||
      parameter.parameter_name.includes("Mins Played")
    );
  };

  const [successPopup, setSuccessPopup] = useState(false);
  const modal = (
    <>
      <Modal isOpen={open} setIsOpen={setOpen} closeIc>
        <h1>{l(1080)}</h1>
        <p>
          {/* Someone from{" "}
          <span style={{ color: "#BFE82D", textDecoration: "underline" }}>
            Smrt Stats
          </span>{" "}
          team will initate contact between you and player{" "} */}
          {l(1081)}
          {playerInfo?.display_name}. {l(1082)}
        </p>
        <Button
          title="confirm"
          size={"full"}
          style={{ marginTop: "30px" }}
          onClick={() => handleSendEmail(playerInfo)}
        />
      </Modal>

      {/* Success added to compare  */}
      <Modal isOpen={successPopup} setIsOpen={setSuccessPopup} closeIc>
        <h1>{l(1046)}</h1>
        <br />
        <Button
          title={l(1047)}
          size={"full"}
          onClick={() => navigate(`/compare`)}
        />
      </Modal>
    </>
  );

  const handleCompare = () => {
    // Retrieve the players array from local storage
    const storedPlayers = localStorage.getItem("players");
    let playersArray = [];

    if (storedPlayers) {
      // Parse the existing players array from local storage
      playersArray = JSON.parse(storedPlayers);
    }

    // Convert id to number (assuming id is initially a string)
    const playerId = parseInt(id, 10);

    // Check if the item ID is already in the array
    if (!playersArray.includes(playerId)) {
      // Add the new player ID to the array
      playersArray.push(playerId);
      // Store the updated array back in local storage
      localStorage.setItem("players", JSON.stringify(playersArray));
    }
    setSuccessPopup(!successPopup);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener(
      "navigationOpenChange",
      handleNavigationOpenChange
    );

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);

  const changeSeasonDropdownRef = useOutsideClick(() => {
    if (openChangeSeason) {
      setOpenChangeSeason(false);
    }
  });

  const getParameterById = useCallback((id) => {
    try {
      if (players?.statistics?.length > 0) {
        const param = players.statistics.find(item => item.parameter_id === id)
        return param;
      }
      return null
    } catch (e) {
      console.log('get player parameter err', e);
      return null
    }
  }, [players?.statistics])

  const obe = useMemo(() => {
    return getParameterById(14);
  }, [getParameterById]);

  const goalsSucc = useMemo(() => {
    return getParameterById(2);
  }, [getParameterById]);

  const assists = useMemo(() => {
    return getParameterById(201);
  }, [getParameterById]);

  const passes = useMemo(() => {
    return getParameterById(35);
  }, [getParameterById]);

  const minutes = useMemo(() => {
    return getParameterById(1);
  }, [getParameterById]);

  const firstGroup = [obe, goalsSucc, assists, passes, minutes]

  const passesSucc = useMemo(() => {
    return getParameterById(36);
  }, [getParameterById]);

  const duelsWon = useMemo(() => {
    return getParameterById(123);
  }, [getParameterById]);

  const shots = useMemo(() => {
    return getParameterById(24);
  }, [getParameterById]);

  const yc = useMemo(() => {
    return getParameterById(21);
  }, [getParameterById]);

  const rc = useMemo(() => {
    return getParameterById(23);
  }, [getParameterById]);

  const secondGroup = [passesSucc, duelsWon, shots, yc, rc]

  const ballRec = useMemo(() => {
    return getParameterById(158);
  }, [getParameterById]);

  const tackles = useMemo(() => {
    return getParameterById(142);
  }, [getParameterById]);

  const shotsFreekick = useMemo(() => {
    return getParameterById(33);
  }, [getParameterById]);

  const goalsPen = useMemo(() => {
    return getParameterById(3);
  }, [getParameterById]);

  const dribblingsSucc = useMemo(() => {
    return getParameterById(113);
  }, [getParameterById]);

  const thirdGroup = [ballRec, tackles, shotsFreekick, goalsPen, dribblingsSucc]

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText smallTitle="Hello John" title={l(1003)} />
          <Search />
        </TopWrapper>

        {loading ? (
          <LoadingWrapper>
            <Oval
              visible={true}
              height="80"
              width="80"
              secondaryColor={
                localStorage.getItem("theme") === "light"
                  ? "#4E5255"
                  : "#CFDAE6"
              }
              color={
                localStorage.getItem("theme") === "light"
                  ? "#2363F7"
                  : "#4fa94d"
              }
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </LoadingWrapper>
        ) : (
          <>
            <Breadcrumbs items={breadcrumbs} />

            <TeamCardWrap>
              <TeamContent>
                <TopContent>
                  <Info>
                    <AvatarWithCountryContainer>
                      <img
                        src={
                          players?.player?.photos.length > 0
                            ? `https://platform.smrtstats.com:8888/${players?.player?.photos[0]?.image}`
                            : emptyState
                        }
                        alt={players?.team?.logo}
                      />
                      {players?.player?.nationality?.[0]?.flag !== null ? (
                        <img
                          className="flag"
                          src={players?.player?.nationality?.[0]?.flag}
                          alt={players?.player?.nationality?.[0]?.name}
                        />
                      ) : (
                        <img
                          className="flag"
                          src={players?.player?.team.logo}
                          alt={players?.player?.team.logo}
                        />
                      )}
                    </AvatarWithCountryContainer>

                    <TeamLeftInfo>
                      {players?.player?.nationality?.length > 0 && (
                        <h5>{players?.player?.nationality?.[0]?.short_name}</h5>
                      )}
                      <h2>
                        {players?.player?.display_name}
                        <img
                          src={isFavorite ? starOn : starOff}
                          alt="Favorite star"
                          style={{
                            width: "18px",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          onClick={toggleFavorite}
                        />
                      </h2>

                      <h3
                        data-tooltip-id={`position-${players?.player?.position[0]?.name}}`}
                      >
                        {players?.player?.position[0]?.name}
                      </h3>

                      <ReactTooltip
                        id={`position-${players?.player?.position[0]?.name}}`}
                        place="bottom"
                        content={players?.player?.position.map(
                          (item, index) => (
                            <h3 key={index}>{item.name}</h3>
                          )
                        )}
                      />
                    </TeamLeftInfo>
                  </Info>
                  {isMobile && (
                    <BottomContent>
                      <TeamStatistics style={{ padding: 0 }}>
                        <Stat>
                          <div>
                            <h5>{l(1004)}</h5>
                            <h4
                              style={{ cursor: players?.player?.team?.id ? 'pointer' : 'not-allowed' }}
                              onClick={() => navigate(`/tournaments/team/${players?.player?.team?.id}`)}
                            >
                              {players?.player?.team?.name}
                            </h4>
                          </div>
                          {/* <div>
                            <h5>Past Club</h5>
                            <h4
                              style={{ cursor: players?.player?.teams?.[1]?.id ? 'pointer' : 'not-allowed' }}
                              onClick={() => navigate(`/tournaments/team/${players?.player?.teams?.[1]?.id}`)}
                            >
                              {players?.player?.teams?.[1]?.name ?? "/"}
                            </h4>
                          </div> */}
                          <div>
                            <h5>{l(115)}</h5>
                            <h4>
                              {" "}
                              {players?.player?.position[1]?.name ?? "/"}
                            </h4>
                          </div>
                          <div>
                            <h5>{l(112)}</h5>
                            <h4>{players?.player?.leg ?? "/"}</h4>
                          </div>
                          <div>
                            <h5>{l(327)}</h5>
                            <h4>
                              {players?.player?.birth_date
                                ? new Date().getFullYear() -
                                new Date(
                                  players.player.birth_date
                                ).getFullYear() -
                                (new Date().getMonth() <
                                  new Date(
                                    players.player.birth_date
                                  ).getMonth() ||
                                  (new Date().getMonth() ===
                                    new Date(
                                      players.player.birth_date
                                    ).getMonth() &&
                                    new Date().getDate() <
                                    new Date(
                                      players.player.birth_date
                                    ).getDate())
                                  ? 1
                                  : 0)
                                : "/"}
                            </h4>
                          </div>
                          <div>
                            <h5>{l(113)}</h5>
                            <h4>{`${players?.player?.height} cm` ?? "/"}</h4>
                          </div>
                          <div>
                            <h5>{l(1006)}</h5>
                            <h4>{players?.player?.weight ?? "/"}</h4>
                          </div>
                          <div>
                            <h5>{l(1007)}</h5>
                            <ButtonLink
                              title={l(1008)}
                              underline
                              color="primary"
                              size="small"
                              onClick={() => handleCompare()}
                            />
                          </div>
                        </Stat>
                      </TeamStatistics>
                    </BottomContent>
                  )}
                  <TeamButtons>
                    <Button
                      color={"secondary"}
                      onClick={() => handleScreenShot()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                        />
                      </svg>
                    </Button>
                    <Button
                      title="Initiate contact"
                      onClick={() => handleInitiate(players)}
                    />
                  </TeamButtons>
                </TopContent>
                {!isMobile && (
                  <BottomContent>
                    <TeamStatistics>
                      <Stat>
                        <div>
                          <h5>{l(1004)}</h5>
                          <h4
                            style={{ cursor: players?.player?.team?.id ? 'pointer' : 'not-allowed' }}
                            onClick={() => navigate(`/tournaments/team/${players?.player?.team?.id}`)}
                          >
                            {players?.player?.team?.name}
                          </h4>
                        </div>
                        {/* <div>
                          <h5>Past Club</h5>
                          <h4
                            style={{ cursor: players?.player?.teams?.[1]?.id ? 'pointer' : 'not-allowed' }}
                            onClick={() => navigate(`/tournaments/team/${players?.player?.teams?.[1]?.id}`)}
                          >
                            {" "}
                            {players?.player?.teams[1]?.name ?? "/"}
                          </h4>
                        </div> */}
                        <div>
                          <h5>{l(115)}</h5>
                          <h4 style={{ textAlign: "center" }}>
                            {players?.player?.position[1]?.name ?? "/"}
                          </h4>
                        </div>
                        <div>
                          <h5>{l(112)}</h5>
                          <h4>{players?.player?.leg ?? "/"}</h4>
                        </div>
                        <div>
                          <h5>{l(327)}</h5>
                          <h4>
                            {players?.player?.birth_date
                              ? new Date().getFullYear() -
                              new Date(
                                players.player.birth_date
                              ).getFullYear() -
                              (new Date().getMonth() <
                                new Date(
                                  players.player.birth_date
                                ).getMonth() ||
                                (new Date().getMonth() ===
                                  new Date(
                                    players.player.birth_date
                                  ).getMonth() &&
                                  new Date().getDate() <
                                  new Date(
                                    players.player.birth_date
                                  ).getDate())
                                ? 1
                                : 0)
                              : "/"}
                          </h4>
                        </div>
                        <div>
                          <h5>{l(113)}</h5>
                          <h4>{`${players?.player?.height} cm` ?? "/"}</h4>
                        </div>
                        <div>
                          <h5>{l(1006)}</h5>

                          <h4>{`${players?.player?.weight} kg` ?? "/"}</h4>
                        </div>
                        <div>
                          <h5>{l(1007)}</h5>
                          <ButtonLink
                            title={l(1008)}
                            underline
                            color="primary"
                            size="small"
                            onClick={() => handleCompare()}
                          />
                        </div>
                      </Stat>
                    </TeamStatistics>
                  </BottomContent>
                )}
              </TeamContent>
            </TeamCardWrap>

            <TabsWrapper forceRenderTabPanel={true}>
              <TopList>
                <TabListWrapper>
                  <TabTitle>{l(988)}</TabTitle>
                  <TabTitle>{l(7)}</TabTitle>
                  <TabTitle>{l(8)}</TabTitle>
                  <TabTitle>{l(9)}</TabTitle>
                  <TabTitle>{l(18)}</TabTitle>
                  <TabTitle>{l(587)}</TabTitle>
                </TabListWrapper>
              </TopList>

              <TabContent hidden={true}>
                <WrappContent style={{ width: "100%" }}>
                  <TopCardInfo>
                    <h2>{l(99)}</h2>
                    {/* <SelectDropdown>
                      <select
                        onChange={handleSelectChange}
                        value={players?.available_seasons?.find(item => item.years === players?.year_season)?.id || selectedSeason}
                      >
                        {players?.available_seasons?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              Season {item.years}
                            </option>
                          );
                        })}
                      </select>
                    </SelectDropdown> */}
                    <DropdownWrapper ref={changeSeasonDropdownRef}>
                      <div
                        style={{ position: "relative" }}
                        onClick={() => setOpenChangeSeason(!openChangeSeason)}
                      >
                        <TextInput
                          type="text"
                          value={currentSelectedSeason}
                          readOnly
                        />

                        <img
                          src={openChangeSeason ? arrowIconUp : arrowIconDown}
                          alt={arrowIconDown}
                          style={{
                            color: "#fff",
                            position: "absolute",
                            right: 0,
                            cursor: "pointer",
                          }}
                        />
                      </div>

                      {openChangeSeason && (
                        <OpenedDropdown>
                          {players?.available_seasons?.map((item) => (
                            <div key={item.id} onClick={() => handleSelectChange({ target: { value: item.id } })}>
                              {item.years}
                            </div>
                          ))}
                        </OpenedDropdown>
                      )}
                    </DropdownWrapper>
                  </TopCardInfo>
                  <OverviewCard
                    style={{ height: "fit-content", scrollbarWidth: "none" }}
                  >
                    <InnerContent className="statisticsWrap">
                      {/* OBE */}
                      <div className="paramsGroup">
                        {firstGroup?.map(param => {
                          if (!param) return null
                          return (
                            <div
                              key={param.parameter_id}
                              className="statistics playersStatistic"
                            >
                              <div className="playersStatisticWrapper">
                                <h5>
                                  {param?.parameter_name}:
                                </h5>
                                <h4
                                  style={
                                    isCanClickPlayerStat(param)
                                      ? { cursor: "not-allowed" }
                                      : { cursor: "pointer" }
                                  }
                                  onClick={() => {
                                    if (!isCanClickPlayerStat(param)) {
                                      const markers = param?.markers?.join(',');
                                      window.open(`/tournaments/match/video?title=${param?.parameter_name} - ${players?.player?.display_name}&markers=${markers}`);
                                    }
                                  }
                                  }
                                >
                                  {
                                    param?.count
                                      ? Number.isInteger(
                                        param?.count
                                      )
                                        ? formatNumber(
                                          param?.count
                                        )
                                        : param?.count.toFixed(1)
                                      : param?.count}
                                </h4>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="paramsGroup">
                        {secondGroup?.map(param => {
                          if (!param) return null
                          return (
                            <div
                              key={param.parameter_id}
                              className="statistics playersStatistic"
                            >
                              <div className="playersStatisticWrapper">
                                <h5>
                                  {param?.parameter_name}:
                                </h5>
                                <h4
                                  style={
                                    isCanClickPlayerStat(param)
                                      ? { cursor: "not-allowed" }
                                      : { cursor: "pointer" }
                                  }
                                  onClick={() => {
                                    if (!isCanClickPlayerStat(param)) {
                                      const markers = param?.markers?.join(',');
                                      window.open(`/tournaments/match/video?title=${param?.parameter_name} - ${players?.player?.display_name}&markers=${markers}`);
                                    }
                                  }
                                  }
                                >
                                  {
                                    param?.count
                                      ? Number.isInteger(
                                        param?.count
                                      )
                                        ? formatNumber(
                                          param?.count
                                        )
                                        : param?.count.toFixed(1)
                                      : param?.count}
                                </h4>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="paramsGroup">
                        {thirdGroup?.map(param => {
                          if (!param) return null
                          return (
                            <div
                              key={param.parameter_id}
                              className="statistics playersStatistic"
                            >
                              <div className="playersStatisticWrapper">
                                <h5>
                                  {param?.parameter_name}:
                                </h5>
                                <h4
                                  style={
                                    isCanClickPlayerStat(param)
                                      ? { cursor: "not-allowed" }
                                      : { cursor: "pointer" }
                                  }
                                  onClick={() => {
                                    if (!isCanClickPlayerStat(param)) {
                                      const markers = param?.markers?.join(',');
                                      window.open(`/tournaments/match/video?title=${param?.parameter_name} - ${players?.player?.display_name}&markers=${markers}`);
                                    }
                                  }
                                  }
                                >
                                  {
                                    param?.count
                                      ? Number.isInteger(
                                        param?.count
                                      )
                                        ? formatNumber(
                                          param?.count
                                        )
                                        : param?.count.toFixed(1)
                                      : param?.count}
                                </h4>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </InnerContent>
                  </OverviewCard>
                </WrappContent>
              </TabContent>
              <TabContent hidden={true}>
                <Matches
                  table={players?.matches_list}
                  onChangeData={(data) => setPlayers(data)}
                  season={selectedSeason}
                  playerTeams={playerMatches?.player?.teams || []}
                />
              </TabContent>
              <TabContent hidden={true}>
                <PlayerStat
                  table={playerMatches}
                  statistics={getPlayerInfoStat}
                  mainStat={players}
                  onDataChange={(season, params, downloadType) =>
                    getPlayerNew(id, season, params, downloadType)
                  }
                // matchStatsNumbers={matchStatsNumbers}
                />
              </TabContent>
              <TabContent hidden={true}>
                <EventsPlayer
                  players={players}
                  paramsList={paramsList}
                  user={userDetail}
                />
              </TabContent>
              <TabContent hidden={true}>
                <ShotsPlayer
                  players={players}
                  paramsList={paramsList}
                  paramsShotList={paramsShotList}
                />
              </TabContent>
              <TabContent hidden={true}>
                <CrossesPlayer
                  players={players}
                  paramsList={paramsList}
                  paramsShotList={paramsCrossesList}
                />
              </TabContent>
            </TabsWrapper>
          </>
        )}
      </MainWrapperFavorite>
      {modal}

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </MainWrapper>
  );
};

export default Index;
