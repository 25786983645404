import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Button from "../../components/button/index";
import Search from "../../components/inputs/Search";

import {
  ClubCard,
  ClubContent,
  ClubsPlayersWrapper,
  ClubsWrapper,
  EmptySearch,
  EmptyState,
  Location,
  NumbersStat,
  PlayerCard,
  PlayersCount,
  PlayersWrapper,
  SearchInputWrapper,
  SearchItem,
  SearhWrap,
  SeeMoreTeam,
  TableNamesTop,
  TeamContent,
  TeamsCard,
  TeamsWrapp,
  TeamsWrapper,
  TopStatCard,
  TopStatisticsWrapper,
  TopTable,
} from "../../style/Tournaments";

// Images
import upIcon from "../../images/ic_up.svg";
import start from "../../images/ic_start.svg";
import next from "../../images/ic_next.svg";
import next_blue from "../../images/next_blue.svg";
import emptyState from "../../images/ic_empty.svg";
import searchIcon from "../../images/ic_search.svg";
import closeDropdown from "../../images/up.svg";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";
import { $apiV1, $apiV2 } from "../../config/api";
import { Select } from "../../components/ui";
import { useLexicon } from "../../hooks/useLexicon";

const Index = () => {
  const [topStat, setTopStat] = useState("");
  const [clubsList, setClubsList] = useState([]);
  const [clubsForLeague, setClubsForLeague] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [selectedCompetitionID, setSelectedCompetitionID] = useState();

  const [defaultSelectValue, setDefaultSelectValue] = useState('');

  const l = useLexicon();

  useEffect(() => {
    if (selectedClub?.seasons?.[0]?.title) {
      setDefaultSelectValue(selectedClub?.seasons?.[0]?.title);
    }
  }, [selectedClub])

  const getTopStats = async () => {
    try {
      const response = await $apiV2.get(`/stats/top_data/`);
      setTopStat(response?.data);
    } catch (error) {
      console.error("Error fetching top stats:", error);
    }
  };

  const getClubStats = async () => {
    try {
      const response = await $apiV1.get(
        `/platform/competition/?default=true&page_size=14`
      );
      setClubsList(response?.data.results || []);
      setFilteredClubs(response?.data.results || []);
    } catch (error) {
      console.error("Error fetching club stats:", error);
    }
  };

  const handleLeague = async (id, club) => {
    try {
      const response = await $apiV1.get(
        `/platform/season/${id}/`
      );
      setClubsForLeague(response?.data);
      setSelectedCompetitionID(id);
      club && setSelectedClub(club);
    } catch (error) {
      console.error("Error fetching league details:", error);
    }
  };

  useEffect(() => {
    getTopStats();
    getClubStats();
  }, []);

  const handleDropdownItemClick = (club) => {
    setSearchTerm(club.name);
    setShowDropdown(false);
    setSelectedClub(club); // Set selected club to show in the section below
    handleLeague(club?.seasons?.[0]?.id);
  };

  const handleSearchChange = async (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    try {
      const response = await $apiV1.get(`/platform/competition/`, {
        params: {
          page: 1,
          search: value,
          page_size: 10,
        },
      });

      setFilteredClubs(response?.data.results || []);
      setSelectedClub(null); // Clear selected club when searching
    } catch (error) {
      console.error("Error fetching club stats:", error);
      // Handle error state if needed
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      try {
        const response = await $apiV1.get(`/platform/competition/`, {
          params: {
            // page: 1,
            search: searchTerm,
            page_size: 100,
          },
        });
        setClubsList(response?.data.results || []);
        setFilteredClubs(response?.data.results || []);
        console.log(clubsList);
      } catch (error) {
        console.error("Error fetching club stats:", error);
      }
    }
  };

  const clearSearch = () => {
    setShowDropdown(false);
    setSearchTerm("");
  };


  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener("navigationOpenChange", handleNavigationOpenChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);



  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle="Hello John"
            title={l(910)}
          />
          <Search />
        </TopWrapper>

        <TopStatisticsWrapper>
          <TopStatCard>
            <NumbersStat>
              <h3>
                {topStat?.count_competiton
                  ? formatNumber(topStat.count_competiton)
                  : topStat?.count_competiton}
              </h3>
              {/* <img src={upIcon} alt={upIcon} /> */}
            </NumbersStat>
            <h5>{l(911)}</h5>
          </TopStatCard>

          <TopStatCard>
            <NumbersStat>
              <h3>
                {topStat?.count_match
                  ? formatNumber(topStat.count_match)
                  : topStat?.count_match}
              </h3>
              {/* <h3> {topStat?.count_match}</h3> */}
              {/* <img src={upIcon} alt={upIcon} /> */}
            </NumbersStat>
            <h5>{l(912)}</h5>
          </TopStatCard>

          <TopStatCard>
            <NumbersStat>
              <h3>
                {topStat?.count_team
                  ? formatNumber(topStat.count_team)
                  : topStat?.count_team}
              </h3>
              {/* <img src={upIcon} alt={upIcon} /> */}
            </NumbersStat>
            <h5>{l(1039)}</h5>
          </TopStatCard>

          <TopStatCard>
            <NumbersStat>
              <h3>
                {topStat?.count_players
                  ? formatNumber(topStat.count_players)
                  : topStat?.count_players}
              </h3>
              {/* <img src={upIcon} alt={upIcon} /> */}
            </NumbersStat>
            <h5>{l(5)}</h5>
          </TopStatCard>
        </TopStatisticsWrapper>

        <ClubsPlayersWrapper>
          <ClubsWrapper>
            <SearchInputWrapper>
              <h5>{l(1)}</h5>
              {/* Searchable Dropdown */}
              <div className="searchable-dropdown">
                <SearhWrap>
                  <img src={searchIcon} alt="search icon" />
                  <input
                    type="text"
                    placeholder={l(913)}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setShowDropdown(true)}
                    onKeyDown={handleKeyPress}
                  />
                  {showDropdown && searchTerm && (
                    <img
                      className="closeDropdown"
                      src={closeDropdown}
                      alt={closeDropdown}
                      onClick={() => clearSearch()}
                    />
                  )}
                </SearhWrap>
                {showDropdown && searchTerm && (
                  <ul className="dropdown-list">
                    {filteredClubs.length > 0 ? (
                      filteredClubs.slice(0, 10).map((club, index) => (
                        <li
                          key={index}
                          onClick={() => handleDropdownItemClick(club)}
                        >
                          <SearchItem>
                            <img src={club?.country?.flag} />{" "}
                            <h5>{club.name}</h5>
                          </SearchItem>
                        </li>
                      ))
                    ) : (
                      <EmptySearch>
                        <h5>{l(914)}</h5>
                      </EmptySearch>
                    )}
                  </ul>
                )}
              </div>
            </SearchInputWrapper>

            {selectedClub && (
              <ClubCard className="selectedClub">
                <ClubContent>
                  <img
                    src={selectedClub.logo}
                    alt={selectedClub.logo}
                    className="flag"
                  />
                  <Location>
                    <h5>{selectedClub.country.name}</h5>
                    <a
                     className="selected"
                      href={`/tournaments/competition/${selectedClub?.seasons?.[0]?.id}`}
                      // onClick={() =>
                      //   navigate(
                      //     `/tournaments/competition/${selectedClub.seasons[0].id}`
                      //   )
                      // }
                      title={selectedClub.name}
                      style={{ cursor: "pointer" }}
                    >
                      {selectedClub.name}
                    </a>
                  </Location>
                </ClubContent>
                <Select
                  data={selectedClub?.seasons?.map(item => ({ ...item, title: `${item.title} | ${item.year_season}` }))}
                  defaultValue={defaultSelectValue}
                  handleClickItem={(item) => handleLeague(item.id)}
                  styleInput={{ color: localStorage.getItem("theme") === "light" ? "#ffffff" : "#181918" }}
                />
              </ClubCard>
            )
            }
            <div style={{
              height: selectedClub ? '365px' : '435px',
              overflow: 'scroll',
            }}>
              {clubsList?.filter(item => item.seasons?.length > 0).map((item, index) => (
                <ClubCard key={index}>
                  <ClubContent>
                    {/* <img src={start} alt={start} /> */}
                    <img src={item.logo} alt={item.logo} className="flag" />
                    <Location>
                      <h5>{item?.country?.name}</h5>
                      <a
                        href={`/tournaments/competition/${item?.seasons[0]?.id}`}
                        title={item?.name}
                        className="tournaments"
                        // onClick={() =>
                        //   navigate(
                        //     `/tournaments/competition/${item.seasons[0].id}`
                        //   )
                        // }
                        style={{ cursor: "pointer" }}
                      >
                        {item?.name}
                      </a>
                    </Location>
                  </ClubContent>
                  <button
                    className="seeClubs"
                    onClick={() => handleLeague(item?.seasons?.[0]?.id, item)}
                  >
                    {localStorage.getItem("theme") === "light" ? (
                      <img src={next_blue} alt={next_blue} />
                    ) : (
                      <img src={next} alt={next} />
                    )}
                  </button>
                </ClubCard>
              ))}
            </div>
          </ClubsWrapper>

          <TeamsWrapp>
            <TopTable>
              <TableNamesTop>
                <h5>{l(1038)}</h5>
                <h5>{l(5)}</h5>

                <div style={{ width: "92px" }}></div>
              </TableNamesTop>
              {clubsForLeague?.teams?.length > 0 && (
                <Button
                  size={"small"}
                  className="seeTournament"
                  title="see competition"
                  onClick={() =>
                    window.open(
                      `/tournaments/competition/${selectedCompetitionID}`,
                      "_blank"
                    )
                  }
                />
              )}
            </TopTable>
            <TeamsWrapper>
              {clubsForLeague?.teams?.length > 0 ? (
                clubsForLeague?.teams?.sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })?.map((item, index) => (
                  <TeamsCard key={index}>
                    <TeamContent
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/tournaments/team/${item.id}`)}
                      onContextMenu={() =>
                        window.open(`/tournaments/team/${item.id}`, "_blank")
                      }
                    >
                      {/* <img src={start} alt={start} /> */}
                      <img src={item.logo} alt={item.logo} className="logo" />
                      <h3>{item.name}</h3>
                    </TeamContent>
                    <PlayersCount>
                      <h3>{item.count_players}</h3>
                    </PlayersCount>
                    <SeeMoreTeam
                      onClick={() => navigate(`/tournaments/team/${item.id}`)}
                      onContextMenu={() =>
                        window.open(`/tournaments/team/${item.id}`, "__blank")
                      }
                    >
                      <h3>{l(963)}</h3>
                    </SeeMoreTeam>
                  </TeamsCard>
                ))
              ) : (
                <EmptyState style={{ height: "100%" }}>
                  <img src={emptyState} alt={emptyState} />
                  <h3>{l(915)}</h3>
                </EmptyState>
              )}
            </TeamsWrapper>
          </TeamsWrapp>
        </ClubsPlayersWrapper>
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Index;
