import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  TopWrapperVideo,
  TopWrapperVideoContent,
  VideoContent,
  VideoPlayerWrapper,
  VideoWrapper
} from '../../../style/VideoStyle';
import { FormWrapPopup, MainWrapper } from '../../../style/DasboardMain';
import Navigation from '../../../components/Navigation/Navigation';
import Button from '../../../components/button';
import TextInput from '../../../components/inputs/TextInput';
import Modal from "../../../components/modal/index";

import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";
import { $apiV1 } from '../../../config/api';
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from "./ShotsPlayer";
import { VideoPlayer } from "./ui/VideoPlayer";
import { NotificationList } from "../../../components/notification/NotificationList";
import { useNotification } from "../../../components/notification/useNotification";
import { breakpoints } from "../../../style/size";
import { useMediaQuery } from "react-responsive";
import { AwayResult, Home, HomeResult, Score, TeamMobile, TeamScore, TeamScoreMobile, TeamScoreMobileContent, TopFunction } from "../../../components/table/Matches";
import Moment from "react-moment";
import { useLexicon } from "../../../hooks/useLexicon";


const Video = () => {
  const l = useLexicon();
  const { id } = useParams();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [searchParams] = useSearchParams();

  const [playlist, setPlaylist] = useState(null);
  const [videoHref, setVideoHref] = useState('');
  const [videoSecond] = useState(0);
  const [offsets, setOffsets] = useState({ start: 10, end: 10 });
  const [loadingVideo, setLoadingVideo] = useState(false);

  const [openAddVideo, setOpenAddVide] = useState(false);
  const [folderName, setFolderName] = useState("");

  const [userPlaylists, setUserPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loadingAddPlaylist, setLoadingAddPlaylist] = useState(false);

  const [record, setRecord] = useState(null);
  const [recordTime, setRecordTime] = useState(null);

  const [matchData, setMatchData] = useState(null);

  const { notifications, addNotification, removeNotification } = useNotification();

  useEffect(() => {
    const getUserPlaylists = async () => {
      try {
        const res = await $apiV1.get('/users/playlists/');
        setUserPlaylists(res.data);
      } catch (e) {
        console.log('get user playlists err', e.message);
      }
    }

    getUserPlaylists();
  }, [])

  useEffect(() => {
    const getMatchInfo = async (id) => {
      try {
        setLoadingVideo(true);
        const response = await $apiV1.get(`/platform/match/${id}/`);
        setMatchData(response.data);
        if (response.data?.videos?.length === 0) {
          throw Error(`No video link`);
        } else {
          const video = response.data?.videos?.find(item => Boolean(item.link));
          if (video?.link) {
            setVideoHref(video.link);
          } else {
            throw Error(`No video link`);
          }
        }
      } catch (e) {
        console.log('get match video error', e.message);
        addNotification(`Get match video error: ${e.message}`, 'error')
      } finally {
        setLoadingVideo(false);
      }
    }
    id && getMatchInfo(+id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const handleSelectChange = (value) => {
    setSelectedPlaylist(value);
  };

  const handleEmailChange = (newValue) => {
    setFolderName(newValue);
  };

  const addRecordToPlaylist = () => {
    setOpenAddVide(true);
  }

  const addNewFolder = async () => {
    try {
      setLoadingAddPlaylist(true);
      let playlist;

      if (!selectedPlaylist) {
        // First API request to create a new folder
        const createFolderResponse = await $apiV1.post(`/users/playlists/`,
          { title: folderName },
        );
        playlist = createFolderResponse.data;
        setUserPlaylists(prev => [...prev, playlist])
      } else {
        playlist = selectedPlaylist;
      }

      setOpenAddVide(!openAddVideo);

      if (record?.id && recordTime?.startTime && recordTime?.endTime) {
        const params = {
          end_second: +(+recordTime.endTime).toFixed(0),
          start_second: +(+recordTime.startTime).toFixed(0),
          event_id: record.id,
          title: `Record from ${recordTime.startTime.toFixed(0)} to ${recordTime.endTime.toFixed(0)}`
        }
        // await getCutVideo(params);
        await $apiV1.post(`/users/playlists/${playlist.id}/add/`,
          [params],
        );
        return
      }
    } catch (error) {
      console.error("Error processing request:", error);
    } finally {
      setLoadingAddPlaylist(false);
    }
  };

  const handleChangeOffSets = (start, end) => {
    setOffsets({ start, end })
  }


  const modal = (
    <Modal isOpen={openAddVideo} setIsOpen={setOpenAddVide} closeIc>
      <h1>
        {l(1027)}
      </h1>
      <FormWrapPopup style={{ marginTop: "20px" }}>
        <Dropdown>
          <DropdownButton
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {!selectedPlaylist
              ? l(1088)
              : `${selectedPlaylist?.title}`}
            <img src={showDropdown ? arrowIconUp : arrowIconDown} />
          </DropdownButton>
          <DropdownContent show={showDropdown}>
            {userPlaylists.map((item) => (
              <DropdownItem
                key={item.id}
                selected={item.id === selectedPlaylist?.id}
                onClick={() => handleSelectChange(item)}
              >
                {item.title}
              </DropdownItem>
            ))}
          </DropdownContent>
        </Dropdown>
        <TextInput
          label={l(1089)}
          type={"text"}
          value={folderName}
          placeholder={l(1090)}
          onChange={handleEmailChange}
        />
      </FormWrapPopup>
      <Button
        style={{ zIndex: 1 }}
        title={l(426)}
        size={"full"}
        onClick={() => addNewFolder()}
        disabled={loadingAddPlaylist}
      />
    </Modal>
  );

  return (
    <MainWrapper>
      <Navigation />
      <VideoWrapper
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapperVideo>
          <TopWrapperVideoContent></TopWrapperVideoContent>
        </TopWrapperVideo>
        {!isMobile && (
          <TeamScore style={{ width: "100%" }}>
            <Home>
              <img
                src={matchData?.home_squad?.team?.logo}
                alt={matchData?.home_squad?.team?.name}
              />{" "}
              <h4 style={{ width: 'auto', maxWidth: '100%' }}>{matchData?.home_squad?.team?.name}</h4>
            </Home>
            <Score>
              <h3>
                <Moment format="DD.MM.YYYY">{matchData?.date}</Moment>
              </h3>
              <h4>
                {matchData?.home_team_score} : {matchData?.away_team_score}
              </h4>
              <h4>{l(118)}</h4>
            </Score>
            <Home className="awayTeam">
              <img
                src={matchData?.away_squad?.team?.logo}
                alt={matchData?.away_squad?.team?.name}
              />{" "}
              <h4 style={{ width: 'auto', maxWidth: '100%' }}>{matchData?.away_squad?.team?.name}</h4>
            </Home>
          </TeamScore>
        )}
        {isMobile && (
          <TeamScoreMobile style={{ width: "95%" }}>
            <TeamScoreMobileContent>
              <TopFunction>
                {" "}
                <h3>
                  <Moment format="DD.MM.YYYY">{matchData?.date}</Moment>
                </h3>
              </TopFunction>
              <HomeResult>
                <TeamMobile>
                  <img
                    src={matchData?.home_squad?.team?.logo}
                    alt={matchData?.home_squad?.team?.name}
                  />{" "}
                  <h4>{matchData?.home_squad?.team?.name}</h4>
                </TeamMobile>

                <h5>{matchData?.home_team_score}</h5>
              </HomeResult>
              <AwayResult>
                <TeamMobile>
                  <img
                    src={matchData?.away_squad?.team?.logo}
                    alt={matchData?.away_squad?.team?.name}
                  />{" "}
                  <h4>{matchData?.away_squad?.team?.name}</h4>
                </TeamMobile>
                <h5>{matchData?.away_team_score}</h5>
              </AwayResult>
            </TeamScoreMobileContent>
          </TeamScoreMobile>
        )}
        <VideoPlayerWrapper>
          <VideoContent>
            <VideoPlayer
              video={videoHref}
              toSecond={videoSecond}
              videos={[]}
              offsets={offsets}
              changeOffSets={handleChangeOffSets}
              loading={loadingVideo}
              record={record}
              setRecord={setRecord}
              recordTime={recordTime}
              setRecordTime={setRecordTime}
              addRecordToPlaylist={addRecordToPlaylist}
              disabledNext={true}
              disabledPrev={true}
              needDownload={true}
            />
          </VideoContent>
        </VideoPlayerWrapper>
      </VideoWrapper>
      {modal}
      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </MainWrapper>
  );
};

export default Video;
