import React, { useState, useEffect, useRef, useMemo } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  Bottom,
  LeftTable,
  MatchTableWrap,
  RightTable,
  ScrollableContainer,
  StyledTable,
  TeamMatches,
  TeamName,
  Top,
  TableButtons,
} from "../../style/MatchTable";

import { Score } from "./Matches";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Filter, { FormWrap } from "../modal/Filter";
import styled from "styled-components";

// Images
import playEmpty from "../../images/ic_play-empty.svg";
import play from "../../images/ic_play.svg";
import playActive from "../../images/ic_play_active.svg";
import playActiveBlue from "../../images/ic_play_active_blue.svg";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import { ReactComponent as SortAscIcon } from "../../images/icons/sortasc_icon.svg";
import { ReactComponent as SortDescIcon } from "../../images/icons/sort_desc.svg";
import { ReactComponent as SortDefaultIcon } from "../../images/icons/sort_default.svg";

import TextInput, { InputWrapper } from "../inputs/TextInput";
import { ParametersSelector } from "../ParametersSelector";
import { ParamsWrap } from "./MatchTable";
import {
  Dropdown,
  DropdownButton,
  DropdownContent,
  DropdownItem,
} from "../../pages/tournametns/team/EventsMatches";
import { $apiV1 } from "../../config/api";
import Button from "../button";
import { getGroupedAndSortedParametrs } from "../../helpers";
import { breakpoints } from "../../style/size";
import { useMediaQuery } from "react-responsive";
import { IconWrapper } from "../../pages/tournametns/player/ui/icons/IconWrapper";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useLexicon } from "../../hooks/useLexicon";

export const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const OpenedDropdown = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: 20px;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.black};
  max-height: 200px;
  overflow: scroll;
  border: 1px solid ${(props) => props.theme.borderColor};

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.row};
    }
  }
`;

export const StickyTableHead = styled.thead`
    position: sticky !important;
    top: 0;
    background-color: ${(props) => props.theme.black};
    z-index: 1;
`

const PlayerStat = (props) => {
  const l = useLexicon();
  const { table, statistics, season, onDataChange, mainStat } = props;
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  const [mathcesList, setMatchesList] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const [lastMatches, SetLastMatches] = useState("");
  const [selectedParams, setSelectedParams] = useState([]);

  const [openMatchesCount, setOpenMatchesCount] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState("");

  const [competitions, setCompetitions] = useState(
    mainStat?.matches_list || []
  );
  const [openCompetition, setOpenCompetition] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState("");
  const [choosenCompetition, setChoosenCompetition] = useState("");
  const [availableSeasons, setAvailableSeason] = useState(
    mainStat?.available_seasons || []
  );
  const [filteredMatches, setFilteredMatches] = useState([]);

  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    checkedIds: [],
    params: [],
  });

  const [choosenSeasson, setChoosenSeasson] = useState(season);

  const [openParams, setOpenParams] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState("");

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [tableTypeSorting, setTableTypeSorting] = useState({ type: 'default', param: '' }); // type default | asc | desc param:number

  const currentSelectedSeason = useMemo(() => {
    if (selectedSeason) return selectedSeason;
    if (availableSeasons?.length) {
      const foundedSeason = availableSeasons?.find((item) => item.id == season);
      if (season && foundedSeason) {
        return foundedSeason?.years;
      }
    }
    return l(992);
  }, [season, selectedSeason, availableSeasons, l]);

  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target === scrollRef1.current) {
        scrollRef2.current.scrollTop = e.target.scrollTop;
      } else if (e.target === scrollRef2.current) {
        scrollRef1.current.scrollTop = e.target.scrollTop;
      }
    };
    if (scrollRef1.current && scrollRef2.current) {
      scrollRef1.current.addEventListener('scroll', handleScroll);
      scrollRef2.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef1.current && scrollRef2.current) {
        scrollRef1.current.removeEventListener('scroll', handleScroll);
        scrollRef2.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (mainStat?.matches_list?.length > 0) {
      const uniqueArray = mainStat?.matches_list?.reduce(
        (accumulator, current) => {
          if (
            accumulator.findIndex(
              (object) => object.competition === current.competition
            ) === -1
          ) {
            accumulator.push(current);
          }
          return accumulator;
        },
        []
      );

      setCompetitions(uniqueArray);
    }
  }, [mainStat]);

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
    setSelectedMathes(
      filteredMatches?.slice(0, item.value)?.map((item) => item.id)
    );
  };

  const tableLeftNames = [
    {
      id: 1,
      name: l(7),
    },
    // {
    //   id: 2,
    //   name: "Position",
    // },
  ];

  const { id } = useParams();
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedMathes, setSelectedMathes] = useState([]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const setParams = async () => {
      try {
        let params = null;
        if (sessionStorage.getItem("playerParameters")) {
          params = JSON.parse(sessionStorage.getItem("playerParameters"));
          const checkedIds = params.flatMap((group) =>
            group[1].filter((item) => item.checked).map((item) => item.id)
          );
          const checkedParams = params.flatMap((group) =>
            group[1].filter((item) => item.checked).map((item) => item.name)
          );
          setTempSelectedFilters({ checkedIds, params: checkedParams });
        } else {
          const response = await $apiV1.get("/stats/parameters/player/");
          const data = response.data?.results;
          if (data) {
            params = getGroupedAndSortedParametrs(data, true);
            const checkedIds = params.flatMap((group) =>
              group[1].map((item) => item.id)
            );
            const checkedParams = params.flatMap((group) =>
              group[1].map((item) => item.name)
            );
            setTempSelectedFilters({ checkedIds, params: checkedParams });
          }
        }
      } catch (e) {
        console.log(`Error in receiving player parameters. ${e.message}`);
      }
    };
    setParams();
  }, []);

  useEffect(() => {
    const getMathesWithSortedParams = (matches) => {
      const newArray = matches?.map((match) => {
        return {
          ...match,
          parameters: match?.parameters?.sort(
            (a, b) => a.parameter_id - b.parameter_id
          ),
        };
      });
      setFilteredMatches(newArray);
    };
    getMathesWithSortedParams(statistics);
    setSelectedMathes(statistics?.map((item) => item.id));
  }, [statistics]);

  const handleParamChange = (e) => {
    const paramId = e.target.value;
    setSelectedParams((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(paramId)) {
        newSet.delete(paramId);
      } else {
        newSet.add(paramId);
      }
      return newSet;
    });
  };

  const matches = [
    {
      id: 1,
      value: statistics.length,
      title: l(1066),
    },
    {
      id: 2,
      value: 3,
      title: l(1067),
    },
    {
      id: 3,
      value: 5,
      title: l(1068),
    },
    {
      id: 4,
      value: 10,
      title: l(1069),
    },
  ];

  const displayFilters = () => {
    if (tempSelectedFilters?.params?.length === 0) {
      return "Choose params";
    }

    const firstTwo = tempSelectedFilters?.params?.slice(0, 2)?.join(", ");
    const additionalCount = tempSelectedFilters?.params?.length - 2;
    return additionalCount > 0 ? `${firstTwo}, +${additionalCount}` : firstTwo;
  };

  function handleChangeParams(params) {
    const checkedIds = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.id)
    );
    const checkedParams = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.name)
    );
    setTempSelectedFilters({ checkedIds, params: checkedParams });
    onDataChange?.(choosenSeasson, checkedIds);
  }

  const handleSeasons = async (season = choosenSeasson) => {
    setLoading(true);
    onDataChange?.(season, tempSelectedFilters.checkedIds)
      .then(async (res) => {
        const response = await $apiV1.get(
          `/statistics/player/${id}?year_season_id=${season}`
        );
        setAvailableSeason(response.data.available_seasons);
        const uniqueArray = response.data.matches_list?.reduce(
          (accumulator, current) => {
            if (
              accumulator.findIndex(
                (object) => object.competition === current.competition
              ) === -1
            ) {
              accumulator.push(current);
            }
            return accumulator;
          },
          []
        );
        setCompetitions(uniqueArray);

        const filteredMatches = res?.data
          ?.filter((match) =>
            choosenCompetition
              ? match.competition_id === choosenCompetition
              : true
          )
          .map((match) => match.id);

        setMatchesList(filteredMatches);

        // Filter matches to show based on the updated list
        // const matchesToShow = response.data.matches_list.filter((match) =>
        //   filteredMatches.includes(match.id)
        // );
      })
      .catch((error) => {
        console.error("Error fetching players:", error);
      })
      .finally(() => setLoading(false));
  };

  const handleSelectionChange = (id) => {
    setSelectedMathes((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(item.competition__id.toString());
    setSelectedCompetition(item.title);
    setOpenCompetition(false);
    const filteredMatches = mathcesList
      ?.filter((match) =>
        item.competition__id.toString()
          ? match.competition_id === item.competition__id.toString()
          : true
      )
      .map((match) => match.id);

    setMatchesList(filteredMatches);
  };

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setOpen(false);
    handleSeasons(item.id);
  };

  const handleClearFilter = () => {
    setChoosenSeasson();
    setSelectedSeason("");
    setCompetitions();
    setSelectedCompetition("");
    SetLastMatches();
    setSelectedMatch("");
    setTempSelectedFilters({ checkedIds: [], params: [] });
    setSelectedMathes([]);
  };

  const handleSearch = async () => {
    if (mathcesList?.length > 0) {
      const updatedFilteredMatches = statistics.filter((match) =>
        mathcesList.includes(match.id)
      );
      setFilteredMatches(updatedFilteredMatches);
    }

    setOpenFilter(false);
  };

  const dowloadXls = async () => {
    try {
      const matches = selectedMathes || [];
      const params = tempSelectedFilters.checkedIds || [];
      const response = await $apiV1.post(`/statistics/player_matches/${id}`, {
        matches,
        params,
      });
      if (response.data.report) {
        const a = document.createElement("a");
        a.href = response.data.report;
        a.download =
          response.data.report.split("/")[
          response.data.report.split("/").length - 1
          ];
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (e) {
      console.log(e);
      alert(`Error: ${e?.response?.data?.message || e?.message}`);
    }
  };

  const handleSortTable = (param) => {
    if (tableTypeSorting.type === 'asc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'desc', param: param.parameter_id })
    } else if (tableTypeSorting.type === 'desc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'default', param: param.parameter_id })
      console.log('set default sort')
    } else {
      setTableTypeSorting({ type: 'asc', param: param.parameter_id })
    }
  }

  const sortTable = (a, b) => {
    if (tableTypeSorting.param) {
      const paramA = a.parameters?.find(param => param.parameter_id === tableTypeSorting.param);
      const paramB = b.parameters?.find(param => param.parameter_id === tableTypeSorting.param);
      if (paramA && paramB) {
        if (tableTypeSorting.type === 'asc') {
          return paramA.value - paramB.value;
        }
        if (tableTypeSorting.type === 'desc') {
          return paramB.value - paramA.value;
        }
      }
      if (paramA) return -1;
      if (paramB) return 1;
    }
    return 0;
  }

  const seasonDropdownRef = useOutsideClick(() => {
    if (open) {
      setOpen(false);
    }
  });

  const competitionDropdownRef = useOutsideClick(() => {
    if (openCompetition) {
      setOpenCompetition(false);
    }
  });

  const mathesCountDropdownRef = useOutsideClick(() => {
    if (openMatchesCount) {
      setOpenMatchesCount(false);
    }
  });

  const dropdownRef = useOutsideClick(() => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  return (
    <>
      {isMobile && (
        <TableButtons>
          <div className="buttonFilterContainer">
            <button
              className="buttonFilter"
              onClick={() => onDataChange?.(selectedSeason, tempSelectedFilters.checkedIds, 'xls')}
            >
              Download in XLS
            </button>
            <button
              className="buttonFilter"
              onClick={() => onDataChange?.(selectedSeason, tempSelectedFilters.checkedIds, 'xml')}
            >
              Download in XML
            </button>
            <button
              className="buttonFilter"
              onClick={() => setOpenFilter(!openFilter)}
            >
              {t("smrtSearch.filter")}
            </button>
          </div>
        </TableButtons>
      )}
      <MatchTableWrap>
        <LeftTable>
          <Top>
            <ul>
              {tableLeftNames.map((item, index) => {
                return <li key={index}>{item.name}</li>;
              })}
            </ul>
          </Top>

          <Bottom style={{ height: '42vh', overflow: 'hidden' }} ref={scrollRef1}>
            {filteredMatches
              ?.filter((item) => selectedMathes.includes(item.id))
              ?.sort(sortTable)
              ?.map((item, index) => {
                return (
                  <TeamMatches key={index} className="playerStatColumnHeight">
                    <TeamName className="matchPlayerStat">
                      <Score className="scorePlayerStat">
                        <h3>
                          <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                          <span
                            title={item.competition}
                          >
                            &nbsp;{item.competition}
                          </span>
                        </h3>

                        <h5
                          data-tooltip-id={`match-${item.id}`}
                          className="playerStatTitle"
                          title={`${item.home_team} - ${item.away_team}, ${item.competition}`}
                        >
                          <span
                            onClick={() => window.open(`/tournaments/team/${item?.home_team_id}`)}
                          >
                            {item.home_team}
                          </span>
                          &nbsp;-&nbsp;
                          <span
                            onClick={() => window.open(`/tournaments/team/${item?.away_team_id}`)}
                          >
                            {item.away_team}
                          </span>

                          <img
                            src={
                              item?.videos.length > 0
                                ? localStorage.getItem("theme") === "light"
                                  ? playActiveBlue
                                  : playActive
                                : playEmpty
                            }
                            alt={play}
                            onClick={() => {
                              if (item?.videos.length > 0) {
                                navigate(`/tournaments/match/video/${item.id}`);
                              }
                            }}
                            onContextMenu={() => {
                              if (item?.videos.length > 0) {
                                window.open(
                                  `/tournaments/match/video/${item.id}`,
                                  "_blank"
                                );
                              }
                            }}
                            style={{ cursor: "pointer", right: 10, position: 'absolute' }}
                            title={l(1019)}
                          />
                        </h5>
                      </Score>
                      {/* <PlayerPosition>
                    {" "}
                    {item.positions.map((item, index) => {
                      return <h5 key={index}>{item?.title}</h5>;
                    })}
                  </PlayerPosition> */}
                    </TeamName>
                  </TeamMatches>
                );
              })}
          </Bottom>
        </LeftTable>

        <RightTable>
          {!isMobile && (
            <div className="buttonFilterContainer">
              <button
                className="buttonFilter"
                onClick={() => onDataChange?.(selectedSeason, tempSelectedFilters.checkedIds, 'xls')}
              >
                {l(14)}
              </button>
              <button
                className="buttonFilter"
                onClick={() => onDataChange?.(selectedSeason, tempSelectedFilters.checkedIds, 'xml')}
              >
                {l(339)}
              </button>
              <button
                className="buttonFilter"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {l(1018)}
              </button>
            </div>
          )}
          <ScrollableContainer style={{ height: '45vh' }} ref={scrollRef2}>
            <StyledTable>
              {/* Assuming `matches` is an array of match objects */}
              <StickyTableHead>
                {/* <tr>
                  {filteredMatches
                    ?.find((item) => item.parameters?.length > 0)
                    ?.parameters?.map((param) => (
                      <React.Fragment key={param.parameter_id}>
                        <th data-tooltip-id={`full-name-${param.parameter_id}`}>
                          {param.parameter_name ?? "/"}
                        </th>
                        <ReactTooltip
                          id={`full-name-${param.parameter_id}`}
                          place="bottom"
                          content={param.parameter_name}
                        />
                      </React.Fragment>
                    ))}
                </tr> */}
                <tr className="playerStatsTable">
                  {filteredMatches
                    ?.find((item) => item.parameters?.length > 0)
                    ?.parameters?.map((param, index) => {
                      // Create a shorthand based on the first letters of the parameter name
                      const shorthand = param.parameter_name
                        .split(" ")
                        .filter((word) => word.length > 0) // Filter out any empty strings
                        .map((word) => word[0].toUpperCase())
                        .join("");

                      return (
                        <th
                          key={param.parameter_id}
                          data-tooltip-id={`name-${param.parameter_id}`}
                          onClick={() => handleSortTable(param)}
                        >
                          {shorthand}
                          {
                            tableTypeSorting.type === 'asc' && tableTypeSorting.param === param.parameter_id ? (
                              <SortAscIcon />
                            ) : tableTypeSorting.type === 'desc' && tableTypeSorting.param === param.parameter_id ? (
                              <SortDescIcon />
                            ) : (
                              <SortDefaultIcon />
                            )
                          }
                          <ReactTooltip
                            id={`name-${param.parameter_id}`}
                            place="bottom"
                            content={param.parameter_name}
                          />
                        </th>
                      );
                    })}
                </tr>
              </StickyTableHead>
              <tbody>
                {tempSelectedFilters?.checkedIds?.length === 0
                  ? null
                  : filteredMatches
                    ?.filter((item) => selectedMathes.includes(item.id))
                    ?.sort(sortTable)
                    ?.map((player, index) => (
                      <tr key={index}>
                        {player?.parameters?.map((stat) => (
                          <td
                            key={stat.parameter_id}
                            style={{
                              cursor: stat.markers != "" ? "pointer" : "",
                              // overflow: 'visible',
                            }}
                            // data-tooltip-id={`value-${player?.id}-${stat?.parameter_id}-${stat?.value}`}
                            onClick={() =>
                              navigate(
                                `/tournaments/match/video?title=${stat.parameter_name
                                } - ${mainStat?.player?.display_name
                                }&markers=${stat.markers?.join(",")}`
                              )
                            }
                          >
                            {Number.isInteger(stat.value)
                              ? stat.value.toLocaleString()
                              : typeof stat.value === "number"
                                ? stat.value.toFixed(1)
                                : stat.value}
                            {/* <ReactTooltip
                              id={`value-${player?.id}-${stat?.parameter_id}-${stat?.value}`}
                              place="bottom"
                              content={stat.parameter_name}
                            /> */}
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </StyledTable>
          </ScrollableContainer>
        </RightTable>
      </MatchTableWrap>

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        onClick={() => handleClearFilter()}
      >
        <FormWrap>
          {availableSeasons && (
            <DropdownWrapper ref={seasonDropdownRef}>
              <div
                style={{ position: "relative" }}
                onClick={() => setOpen(!open)}
              >
                <TextInput
                  type="text"
                  value={currentSelectedSeason}
                  readOnly
                />

                <img
                  src={open ? arrowIconUp : arrowIconDown}
                  alt={arrowIconDown}
                  style={{
                    color: "#fff",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                  }}
                />
              </div>
              {open && (
                <OpenedDropdown>
                  {availableSeasons?.map((item, index) => (
                    <div key={index} onClick={() => handleSeasonChoose(item)}>
                      {item.years}
                    </div>
                  ))}
                </OpenedDropdown>
              )}
            </DropdownWrapper>
          )}
          {competitions && (
            <DropdownWrapper ref={competitionDropdownRef}>
              <div
                style={{ position: "relative" }}
                onClick={() => setOpenCompetition(!openCompetition)}
              >
                <TextInput
                  type="text"
                  value={
                    selectedCompetition.length == 0
                      ? l(993)
                      : selectedCompetition
                  }
                  readOnly
                />

                <img
                  src={openCompetition ? arrowIconUp : arrowIconDown}
                  alt={arrowIconDown}
                  style={{
                    color: "#fff",
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                  }}
                />
              </div>
              {openCompetition && (
                <OpenedDropdown>
                  {competitions?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleCompetitionChoose(item)}
                    >
                      {item.competition}
                    </div>
                  ))}
                </OpenedDropdown>
              )}
            </DropdownWrapper>
          )}
          <DropdownWrapper ref={mathesCountDropdownRef}>
            <div
              style={{ position: "relative" }}
              onClick={() => setOpenMatchesCount(!openMatchesCount)}
            >
              <TextInput
                type="text"
                value={selectedMatch.length == 0 ? l(994) : selectedMatch}
                readOnly
              />

              <img
                src={openMatchesCount ? arrowIconUp : arrowIconDown}
                alt={arrowIconDown}
                style={{
                  color: "#fff",
                  position: "absolute",
                  right: 0,
                  cursor: "pointer",
                }}
              />
            </div>

            {openMatchesCount && (
              <OpenedDropdown>
                {matches?.map((item, index) => (
                  <div key={index} onClick={() => handleMatches(item)}>
                    {item.title}
                  </div>
                ))}
              </OpenedDropdown>
            )}
          </DropdownWrapper>

          <Dropdown ref={dropdownRef}>
            <DropdownButton onClick={() => setShowDropdown(!showDropdown)}>
              {selectedMathes?.length === 0
                ? l(12)
                : `${selectedMathes?.length} selected`}
              <img src={showDropdown ? arrowIconUp : arrowIconDown} />
            </DropdownButton>
            <DropdownContent show={showDropdown}>
              {filteredMatches?.map((item, index) => (
                <DropdownItem
                  key={index}
                  selected={selectedMathes.includes(item.id)}
                  onClick={() => handleSelectionChange(item.id)}
                >
                  {item.home_team} {item.score} {item.away_team}{" "}
                  {item.competition}
                </DropdownItem>
              ))}
            </DropdownContent>
          </Dropdown>

          <ParamsWrap>
            <InputWrapper>
              <input
                type="text"
                value={displayFilters()}
                readOnly
                style={{ width: "100%", marginBottom: "10px" }}
                onClick={() => setOpenParams(!openParams)}
              />
              <img
                className="dropdownIcon"
                src={openParams ? arrowIconUp : arrowIconDown}
                alt={openParams ? arrowIconUp : arrowIconDown}
                onClick={() => setOpenParams(!openParams)}
              />
            </InputWrapper>
          </ParamsWrap>

          {/* <div style={{ height: "300px", overflow: "scroll" }}>
            <h4>Select Parameters</h4>
            {table?.statistics[0].parameters.map((param) => (
              <div key={param.parameter_id}>
                <input
                  type="checkbox"
                  value={param.parameter_id}
                  checked={selectedParams.has(param.parameter_id)}
                  onChange={handleParamChange}
                />
                <label style={{ color: "#fff" }}>{param.parameter_name}</label>
              </div>
            ))}
          </div> */}
          {loading ? (
            <Button title={l(312)} disabled />
          ) : (
            <Button title={l(906)} onClick={() => handleSearch()} />
          )}
        </FormWrap>
      </Filter>

      <ParametersSelector
        type={
          table?.position?.findIndex((item) => item.id === 10) >= 0
            ? "gk"
            : "player"
        }
        open={openParams}
        onClose={() => setOpenParams(false)}
        updateData={handleChangeParams}
      />
    </>
  );
};

export default PlayerStat;
