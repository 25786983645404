import styled from "styled-components";


const ButtonLanguageContainer = styled.div`
    display: flex;
    width: 90%;
    align-items: center;
    gap: 25px;

    & > svg > path {
        fill: red;
    }
`

const Button = styled.button`
    cursor: pointer;
    border: none;
    font-weight: 700;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    & > span {
        color: ${(props) => props.theme.blueish4};
        font-family: "SatoshiMedium";
        font-size: 16px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        width: max-content;
    }
`

export const ButtonLanguage = ({
    fullLang,
    handleClick,
    image,
    lang,
}) => {
    if (lang === 'ar') return <></>;
    return (
        <ButtonLanguageContainer>
            <div>
                {lang === 'en' ? (
                    <span
                        role='img'
                        className='fflag fflag-US ff-xl ff-round'
                    />
                )
                    : <img width={30} height={25} src={image} alt='flag' />}
            </div>
            <Button
                type='button'
                onClick={() => handleClick(lang)}
            >
                <span>{fullLang}</span>
            </Button>
        </ButtonLanguageContainer>
    )
}
