import { useEffect, useState } from "react";
import Button from "../button";
import { useNotification } from "../notification/useNotification";
import { ParametersModal } from "./ParametersModal"
import { $apiV1 } from "../../config/api";
import { getGroupedAndSortedParametrs } from "../../helpers";
import { goalkeepersModalParams } from "../../config/parametrs/goalkeepersModalParams";
import { NotificationList } from "../notification/NotificationList";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";
import { useLexicon } from "../../hooks/useLexicon";

const ParametersColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
`

export const ActionButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;
    position: fixed;
    bottom: 35px;
    left: 50%;
    transform: translate(-50%, 0);
`

export const ParamCheckboxStyle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  text-transform: capitalize;
  white-space: nowrap;

  label {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  label.group-title {
    color: ${(props) => props.theme.green};
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.green}; /* Green color */
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;

    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 0.6em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 21%, 80% 0, 43% 62%);
    transform: scale(0);
    transition: transform 0.15s ease-in-out;
    background-color: ${(props) =>
      props.theme.green}; /* Green color for the check */
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
`;

const ParameterGroup = (props) => {
    const { array, title, callback } = props;

    const [groupChecked, setGroupChecked] = useState(true);

    useEffect(() => {
        if (array.filter(item => !item.checked).length) {
            setGroupChecked(false);
        }
    }, [array]);

    const handleClickTitle = () => {
        array.forEach((item) => {
            groupChecked
                ? item.checked = false
                : item.checked = true
        })
        setGroupChecked(!groupChecked);
    }

    return (
        <ParametersColumn>
            <ParamCheckboxStyle>
                <label
                    htmlFor={`checkbox-${title}`}
                    style={{ fontWeight: 600 }}
                    className="group-title"
                >
                    {title}
                </label>
                <input
                    type="checkbox"
                    id={`checkbox-${title}`}
                    checked={groupChecked}
                    onChange={() => handleClickTitle()}
                />
            </ParamCheckboxStyle>
            {array.map(item => {
                return (
                    <ParamCheckboxStyle key={item.name}>
                        <label
                            htmlFor={`checkbox-${item.name}`}
                        >
                            {item.name}
                        </label>
                        <input
                            type="checkbox"
                            id={`checkbox-${item.name}`}
                            checked={item.checked}
                            onChange={(e) => callback(item, e.target.checked)}
                        />
                    </ParamCheckboxStyle>
                )
            })}
        </ParametersColumn>
    )
}

export const ParametersSelector = (props) => {
    const {
        open,
        onClose,
        type = 'team', // team | player | gk
        updateData,
    } = props;
    const l = useLexicon();

    const { notifications, addNotification, removeNotification } = useNotification();

    const [params, setParams] = useState([]);
    const [loadingParams, setLoadingParams] = useState(true);

    useEffect(() => {
        const getTeamParams = async (type) => {
            if (type === 'player' || type === 'gk') return;
            try {
                setLoadingParams(true);
                let params = null;
                if (sessionStorage.getItem('teamParameters')) {
                    params = JSON.parse(sessionStorage.getItem('teamParameters'))
                } else {
                    const response = await $apiV1.get('/stats/parameters/team/');
                    const data = response.data?.results;
                    if (data) {
                        params = getGroupedAndSortedParametrs(data);
                    }
                }
                setParams(params);
            } catch (e) {
                addNotification(`Error in receiving team parameters. ${e.message}`, 'error')
            } finally {
                setLoadingParams(false);
            }
        }

        const getPlayerParams = async (type) => {
            if (type === 'team') return;
            try {
                setLoadingParams(true);
                let params = null;
                if (type === 'player' && sessionStorage.getItem('playerParameters')) {
                    params = JSON.parse(sessionStorage.getItem('playerParameters'))
                } else if (type === 'gk' && sessionStorage.getItem('gkPlayerParameters')) {
                    params = JSON.parse(sessionStorage.getItem('gkPlayerParameters'))
                } else {
                    const response = await $apiV1.get(`/stats/parameters/player/`);
                    const data = response?.data?.results;
                    if (data) {
                        if (type === 'player') {
                            params = getGroupedAndSortedParametrs(data);

                        } else {
                            const gkParamIds = goalkeepersModalParams.map(item => item.paramId);
                            const playerGkParams = data.filter(item => gkParamIds.includes(item.id));
                            params = getGroupedAndSortedParametrs(playerGkParams, true);
                        }
                    }
                }
                setParams(params)
            } catch (e) {
                addNotification(`Error in receiving player parameters. ${e.message}`, 'error')
            } finally {
                setLoadingParams(false);
            }
        };

        getTeamParams(type);
        getPlayerParams(type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    const handleCheckEvent = (item, checked) => {
        const updatedParams = params.map(group => {
            const isParamExist = group[1].findIndex(a => a.id === item.id);
            if (isParamExist >= 0) {
                return [
                    group[0],
                    group[1].map(b => {
                        if (b.id === item.id) {
                            return { ...b, checked };
                        }
                        return b;
                    })
                ];
            }
            return group;
        });
        setParams(updatedParams);
    }

    const handleClickConfirm = () => {
        type === 'gk' ?
            sessionStorage.setItem('gkPlayerParameters', JSON.stringify(params))
            : type === 'player'
                ? sessionStorage.setItem('playerParameters', JSON.stringify(params))
                : sessionStorage.setItem('teamParameters', JSON.stringify(params));
        onClose?.()
        updateData?.(params);
    }

    const handleClickReset = () => {
        type === 'gk' ?
            sessionStorage.setItem('gkPlayerParameters', JSON.stringify(params))
            : type === 'player'
                ? sessionStorage.setItem('playerParameters', JSON.stringify(params))
                : sessionStorage.setItem('teamParameters', JSON.stringify(params));

        const updatedParams = params.map(group => {
            return [
                group[0],
                group[1].map(b => {
                    return { ...b, checked: false };
                })
            ];
        });
        setParams(updatedParams)
        updateData?.(updatedParams)
    }

    return (
      <ParametersModal open={open} onClose={onClose}>
        {loadingParams ? (
          <Oval
            secondaryColor={
              localStorage.getItem("theme") === "light" ? "#4E5255" : "#CFDAE6"
            }
            wrapperStyle={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          />
        ) : (
          <>
            {params.map(([key, value]) => {
              return (
                <ParameterGroup
                  key={key}
                  title={key}
                  array={value}
                  callback={handleCheckEvent}
                />
              );
            })}
          </>
        )}
        <ActionButtonsContainer>
          <Button
            disabled={loadingParams || params.length === 0}
            title={l(446)}
            onClick={handleClickConfirm}
          />
          <Button
            disabled={loadingParams || params.length === 0}
            title={l(457)}
            onClick={handleClickReset}
          />
        </ActionButtonsContainer>

        {/* display notifications */}
        <NotificationList
          notifications={notifications}
          onClose={removeNotification}
        />
      </ParametersModal>
    );
}