import React, { useState, useMemo, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TextInput, { InputWrapper } from "../inputs/TextInput";
import Button from "../button/index";
import {
  Action,
  Bottom,
  LeftTable,
  MatchContent,
  MatchInfo,
  MatchTableWrap,
  Score,
  TeamMatches,
  TeamName,
  TeamScore,
  Top,
  RightTable,
  ScrollableContainer,
  StyledTable,
  TableButtons,
} from "../../style/MatchTable";
import Moment from "react-moment";
import Filter, { FormWrap } from "../modal/Filter";
import { Formation } from "../Formation";
import { PageButton, PaginationWrapper } from "../../style/Favorites";
import {
  DropdownWrapper,
  OpenedDropdown,
  ParamCheckboxStyle,
  ParamsWrap,
} from "./MatchTable";

import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import more from "../../images/ic_more.svg";
import play from "../../images/ic_play.svg";
import playMatchBlue from "../../images/play_match_blue.svg";
import playInBall from "../../images/ic_playinball.svg";
import playMatchDarkActive from "../../images/ic_play_active.svg";
import playMatchBlueActive from "../../images/ic_play_active_blue.svg";
import playInBallBlue from "../../images/playInBall_blue.svg";
import statistics from "../../images/ic_statistics.svg";
import statisticsBlue from "../../images/statistics_blue.svg";
import formation from "../../images/ic_formation.svg";
import formationBlue from "../../images/formation_blue.svg";
import arrowDark from "../../images/arrow_dark.svg";
import arrowLight from "../../images/arrow_white.svg";

import { ReactComponent as SortAscIcon } from "../../images/icons/sortasc_icon.svg";
import { ReactComponent as SortDescIcon } from "../../images/icons/sort_desc.svg";
import { ReactComponent as SortDefaultIcon } from "../../images/icons/sort_default.svg";

import { $apiV1 } from "../../config/api";
import { ParametersSelector } from "../ParametersSelector";
import { Pagination } from "../ui";
import { LoadingWrapper } from "../../style/Global";
import { Oval } from "react-loader-spinner";
import useOutsideClick from "../../hooks/useOutsideClick";
import { StickyTableHead } from "./PlayerStat";
import { useLexicon } from "../../hooks/useLexicon";

const CompetitionMatch = ({
  table = [],
  load,
  currentPage,
  totalPages,
  onPageChange,
  teamParams,
  availableSeasons = [],
  matchesCount,
  initialSeasonTitle,
}) => {
  const l = useLexicon()
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]); // State for selected filters
  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    checkedIds: [],
    params: [],
  }); // Temporary state for filters
  const [numMatches, setNumMatches] = useState(10); // State for number of matches to display

  const [openFormation, setOpenFormation] = useState(false);
  const [selectedFormationMatch, setSelectedFormationMatch] = useState(null);

  const [selectedMatch, setSelectedMatch] = useState("");
  const [openMatchesCount, setOpenMatchesCount] = useState(false);
  const [lastMatches, SetLastMatches] = useState("");
  const [openParams, setOpenParams] = useState(false);
  const [downloadFile, setDownloadFile] = useState("");

  const [tableTypeSorting, setTableTypeSorting] = useState({ type: 'default', param: '' }); // type default | asc | desc param:number


  const [openSelectSeason, setOpenSelectSeason] = useState(false);

  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target === scrollRef1.current) {
        scrollRef2.current.scrollTop = e.target.scrollTop;
      } else if (e.target === scrollRef2.current) {
        scrollRef1.current.scrollTop = e.target.scrollTop;
      }
    };
    if (scrollRef1.current && scrollRef2.current) {
      scrollRef1.current.addEventListener('scroll', handleScroll);
      scrollRef2.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef1.current && scrollRef2.current) {
        scrollRef1.current.removeEventListener('scroll', handleScroll);
        scrollRef2.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const setDefaultSelectedParams = () => {
      const checkedIds = teamParams.flatMap((group) =>
        group[1].filter((item) => item.checked).map((item) => item.id)
      );
      const checkedParams = teamParams.flatMap((group) =>
        group[1].filter((item) => item.checked).map((item) => item.name)
      );
      setTempSelectedFilters({ checkedIds, params: checkedParams });
    };
    teamParams?.length > 0 && setDefaultSelectedParams();
  }, [teamParams]);

  const handleMatches = (item) => {
    setNumMatches(item.value);
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
  };

  const navigate = useNavigate();

  const tableLeftNames = [
    { id: 1, name: l(991) },
    { id: 2, name: l(123) },
  ];

  const setMoreInfo = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const handleFilterChange = (filter) => {
    setTempSelectedFilters((prevFilters) =>
      prevFilters?.includes(filter)
        ? prevFilters.filter((item) => item !== filter)
        : [...prevFilters, filter]
    );
  };

  const applyFilter = () => {
    setSelectedFilters(tempSelectedFilters);
    setOpenFilter(false);
  };

  // Filter the table based on the selected number of matches
  const filteredTable = useMemo(() => {
    return table.slice(0, numMatches);
  }, [table, numMatches]);

  // Determine which keys to display based on selected filters
  const filteredKeys = selectedFilters.length
    ? selectedFilters
    : filteredTable.length > 0
      ? Object.keys(filteredTable[0]?.away_team?.stats || {})
      : [];

  const handleCloseFormation = () => {
    setOpenFormation(false);
    setSelectedFormationMatch(null);
  };

  const handleClearFilter = () => {
    SetLastMatches();
    setSelectedMatch("");
    setTempSelectedFilters("");
    setNumMatches(table.length);
  };

  useEffect(() => {
    // Fetch data whenever currentPage changes
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const matches = [
    {
      id: 1,
      value: table?.length,
      title: l(1066),
    },
    {
      id: 2,
      value: 3,
      title: l(1067),
    },
    {
      id: 3,
      value: 5,
      title: l(1068),
    },
    {
      id: 4,
      value: 10,
      title: l(1069),
    },
  ];

  const displayFilters = () => {
    if (tempSelectedFilters?.params?.length === 0) {
      return "Choose params";
    }

    const firstTwo = tempSelectedFilters?.params?.slice(0, 2)?.join(", ");
    const additionalCount = tempSelectedFilters?.params?.length - 2;
    return additionalCount > 0 ? `${firstTwo}, +${additionalCount}` : firstTwo;
  };

  const matchIds = table.map((item) => item.id);

  const [loadDownloadXls, setDownloadXls] = useState(false);
  const handleDownloadXls = async () => {
    setDownloadXls(true);
    try {
      const response = await $apiV1.post(`/statistics/match_stats_export`, {
        file_type: "xlsx",
        matches: matchIds,
      });

      const fileUrl = response?.data?.file_url;
      if (fileUrl) {
        // Create a temporary <a> element
        const a = document.createElement("a");
        a.href = `${fileUrl}`;
        a.download = "file.xlsx"; // Specify the default filename
        a.style.display = "none"; // Hide the element
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up
      } else {
        console.error("File URL not found in response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setDownloadXls(false);
    }
  };

  const getWinner = (score, isHome) => {
    let scoreColor;
    if (isHome) {
      if (+score[0] > +score[2]) {
        scoreColor = "#36e82d";
      } else if (+score[0] === +score[2]) {
        scoreColor = "#E8D02D";
      } else {
        scoreColor = "#f20a46";
      }
    } else if (+score[2] > +score[0]) {
      scoreColor = "#36e82d";
    } else if (+score[0] === +score[2]) {
      scoreColor = "#E8D02D";
    } else {
      scoreColor = "#f20a46";
    }
    return scoreColor;
  };

  function handleChangeParams(params) {
    const checkedIds = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.id)
    );
    const checkedParams = params.flatMap((group) =>
      group[1].filter((item) => item.checked).map((item) => item.name)
    );
    setTempSelectedFilters({ checkedIds, params: checkedParams });
  }

  const handleChangeSeason = (season) => {
    navigate(`/tournaments/competition/${season.id}`);
  }

  const handleSortTable = (param) => {
    if (tableTypeSorting.type === 'asc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'desc', param: param.parameter_id })
    } else if (tableTypeSorting.type === 'desc' && param.parameter_id === tableTypeSorting.param) {
      setTableTypeSorting({ type: 'default', param: param.parameter_id })
    } else {
      setTableTypeSorting({ type: 'asc', param: param.parameter_id })
    }
  }

  const sortTable = (a, b) => {
    if (tableTypeSorting.param) {
      const paramA1 = a.home_team?.stats?.find(param => param.parameter_id === tableTypeSorting.param);
      const paramA2 = a.away_team?.stats?.find(param => param.parameter_id === tableTypeSorting.param);

      const paramB1 = b.home_team?.stats?.find(param => param.parameter_id === tableTypeSorting.param);
      const paramB2 = b.away_team?.stats?.find(param => param.parameter_id === tableTypeSorting.param);

      if (paramA1 && paramA2 && paramB1 && paramB2) {
        const sumA = paramA1.value + paramA2.value;
        const sumB = paramB1.value + paramB2.value;

        if (tableTypeSorting.type === 'asc') {
          return sumA - sumB;
        }
        if (tableTypeSorting.type === 'desc') {
          return sumB - sumA;
        }
      }
      return new Date(b.date) - new Date(a.date);
    }
    return new Date(b.date) - new Date(a.date);
  }

  const seasonDropdownRef = useOutsideClick(() => {
    if (openSelectSeason) {
      setOpenSelectSeason(false);
    }
  });

  const mathesCountDropdownRef = useOutsideClick(() => {
    if (openMatchesCount) {
      setOpenMatchesCount(false);
    }
  });

  return (
    <>
      <>
        {isMobile && (
          <TableButtons>
            <button
              className="buttonFilter"
              onClick={() => handleDownloadXls()}
              style={{ width: "100%" }}
            >
              {loadDownloadXls ? l(312) : l(14)}
            </button>
            <button
              className="buttonFilter"
              onClick={() => setOpenFilter(!openFilter)}
            >
              {l(1018)}
            </button>
          </TableButtons>
        )}

        {/* <TournamentMatchesTable teamParams={teamParams} loading={load} matchesStat={table} id={id} /> */}
        <LoadingWrapper style={{ height: '50vh', display: load ? 'flex' : 'none' }}>
          <Oval
            visible={true}
            height="80"
            width="80"
            secondaryColor={
              localStorage.getItem("theme") === "light"
                ? "#4E5255"
                : "#CFDAE6"
            }
            color={
              localStorage.getItem("theme") === "light"
                ? "#2363F7"
                : "#4fa94d"
            }
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </LoadingWrapper>
        <MatchTableWrap style={{ display: load ? 'none' : 'flex' }}>
          <LeftTable>
            <Top>
              <ul>
                {tableLeftNames.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))}
              </ul>
            </Top>
            <Bottom style={{ height: '57vh', overflow: 'hidden' }} ref={scrollRef1}>
              {filteredTable &&
                filteredTable?.sort(sortTable)?.map((item, index) => (
                  <TeamMatches key={index}>
                    <TeamName>
                      <Score>
                        <h3>
                          <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                        </h3>
                        <h4
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.open(`/tournaments/team/${item?.home_team?.id}`)}
                          title={item?.home_team?.name}
                        >
                          {item?.home_team?.name}
                        </h4>
                        <h4
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.open(`/tournaments/team/${item?.away_team?.id}`)}
                          title={item?.away_team?.name}
                        >
                          {item?.away_team?.name}
                        </h4>
                      </Score>
                    </TeamName>
                    <TeamScore
                      style={{ position: "relative", cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          `/tournaments/statistics/${item.id}`,
                          "__blank"
                        )
                      }}>
                      <span
                        style={{
                          color: getWinner(item.score, true),
                        }}
                      >
                        {item?.home_team_score}
                      </span>
                      <span
                        style={{
                          color: getWinner(item.score, false),
                        }}
                      >
                        {item?.away_team_score}
                      </span>
                    </TeamScore>

                    <img
                      className="more"
                      src={more}
                      alt="more"
                      onClick={() => setMoreInfo(index)}
                    />
                    {openRow === index && (
                      <MatchInfo className="competitionMathcInfo">
                        <MatchContent>
                          <Action
                            onClick={() =>
                              navigate(`/tournaments/match/video/${item.id}`)
                            }
                            onContextMenu={() =>
                              window.open(
                                `/tournaments/match/video/${item.id}`,
                                "__blank"
                              )
                            }
                          >
                            <img
                              src={
                                localStorage.getItem("theme") === "light"
                                  ? item?.video
                                    ? playMatchBlueActive
                                    : playMatchBlue
                                  : item?.video
                                    ? playMatchDarkActive
                                    : play
                              }
                              alt={play}
                            />
                            <h4
                              className={
                                item?.video ? "haveMatch" : ""
                              }
                            >
                              {l(1019)}{" "}
                            </h4>
                          </Action>
                          <Action>
                            <img
                              src={
                                localStorage.getItem("theme") === "light"
                                  ? playInBallBlue
                                  : playInBall
                              }
                              alt="playInBall"
                            />
                            <h4>{l(334)}</h4>
                          </Action>
                          <Action
                            onClick={() =>
                              navigate(`/tournaments/statistics/${item.id}`)
                            }
                            onContextMenu={() =>
                              window.open(
                                `/tournaments/statistics/${item.id}`,
                                "__blank"
                              )
                            }
                          >
                            <img
                              src={
                                localStorage.getItem("theme") === "light"
                                  ? statisticsBlue
                                  : statistics
                              }
                              alt="statistics"
                            />
                            <h4>{l(1020)}</h4>
                          </Action>
                          <Action
                            onClick={() => {
                              setSelectedFormationMatch(item);
                              setOpenFormation(true);
                            }}
                            onContextMenu={() => {
                              setSelectedFormationMatch(item);
                              setOpenFormation(true);
                            }}
                          >
                            <img
                              src={
                                localStorage.getItem("theme") === "light"
                                  ? formationBlue
                                  : formation
                              }
                              alt="formation"
                            />
                            <h4>{l(1021)}</h4>
                          </Action>
                        </MatchContent>
                      </MatchInfo>
                    )}
                  </TeamMatches>
                ))}
            </Bottom>
          </LeftTable>

          <RightTable>
            {!isMobile && (
              <TableButtons>
                <button
                  className="buttonFilter"
                  onClick={() => handleDownloadXls()}
                  style={{ width: "100%" }}
                >
                  {loadDownloadXls ? l(312) : l(14)}
                </button>
                <button
                  className="buttonFilter"
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  {l(1018)}
                </button>
              </TableButtons>
            )}

            <ScrollableContainer ref={scrollRef2} style={{ height: '60vh' }}>
              <StyledTable>
                <StickyTableHead>
                  <tr>
                    {filteredTable
                      ?.find((match) => match?.home_team?.stats?.length > 0)
                      ?.home_team?.stats?.map((param) => {
                        if (
                          !tempSelectedFilters?.checkedIds?.includes(
                            param.parameter_id
                          )
                        )
                          return null;

                        // Create a shorthand based on the first letters of the parameter name
                        const shorthand = param.parameter_name
                          .split(" ")
                          .filter((word) => word.length > 0) // Filter out any empty strings
                          .map((word) => word[0].toUpperCase())
                          .join("");

                        return (
                          <React.Fragment key={param.parameter_id}>
                            <th
                              data-tooltip-id={`full-name-${param.parameter_id}`}
                              onClick={() => handleSortTable(param)}
                            >
                              {shorthand}
                              {
                                tableTypeSorting.type === 'asc' && tableTypeSorting.param === param.parameter_id ? (
                                  <SortAscIcon />
                                ) : tableTypeSorting.type === 'desc' && tableTypeSorting.param === param.parameter_id ? (
                                  <SortDescIcon />
                                ) : (
                                  <SortDefaultIcon />
                                )
                              }
                            </th>
                            <ReactTooltip
                              id={`full-name-${param.parameter_id}`}
                              place="bottom"
                              content={param.parameter_name}
                            />
                          </React.Fragment>
                        );
                      })}
                  </tr>
                </StickyTableHead>
                <tbody className="matchCompetition">
                  {filteredTable?.sort(sortTable)?.map((match, matchIndex) => {
                    return (
                      <tr key={match.id} style={{ height: "96.5px" }}>
                        {match?.home_team?.stats?.map((stat, idx) => {
                          if (
                            !tempSelectedFilters?.checkedIds?.includes(
                              stat.parameter_id
                            )
                          )
                            return null;
                          return (
                            <React.Fragment key={stat.paramter_id}>
                              <td className="tournamentTable">
                                <span
                                  onClick={() =>
                                    stat?.markers?.length > 0 &&
                                    navigate(
                                      `/tournaments/match/video?title=${stat.parameter_name
                                      } - ${match?.home_team?.name
                                      }&markers=${stat?.markers?.join(",")}`
                                    )
                                  }
                                  style={{
                                    cursor:
                                      stat?.markers?.length > 0 && "pointer",
                                  }}
                                  onContextMenu={() =>
                                    stat?.markers?.length > 0 &&
                                    window.open(
                                      `/tournaments/match/video?title=${stat.parameter_name
                                      } - ${match?.home_team?.name
                                      }&markers=${stat?.markers?.join(",")}`
                                    )
                                  }
                                >
                                  {/* {stat.value} */}
                                  {Math.round(stat.value)}
                                </span>
                                <br />{" "}
                                <span
                                  onClick={() =>
                                    match.away_team?.stats?.[idx]?.markers
                                      ?.length > 0 &&
                                    navigate(
                                      `/tournaments/match/video?title=${stat.parameter_name
                                      } - ${match?.away_team?.name
                                      }&markers=${match.away_team?.stats?.[
                                        idx
                                      ]?.markers?.join(",")}`
                                    )
                                  }
                                  style={{
                                    cursor:
                                      match.away_team?.stats?.[idx]?.markers
                                        ?.length > 0 && "pointer",
                                  }}
                                  onContextMenu={() =>
                                    match.away_team?.stats?.[idx]?.markers
                                      ?.length > 0 &&
                                    window.open(
                                      `/tournaments/match/video?title=${stat.parameter_name
                                      } - ${match?.away_team?.name
                                      }&markers=${match.away_team?.stats?.[
                                        idx
                                      ]?.markers?.join(",")}`
                                    )
                                  }
                                >
                                  {/* {match.away_team?.stats?.[idx]?.value || 0} */}
                                  {Math.round(
                                    match.away_team?.stats?.[idx]?.value || 0
                                  )}
                                </span>
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </StyledTable>
            </ScrollableContainer>
          </RightTable>

          <Filter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            setTempSelectedFilters={setTempSelectedFilters}
            setNumMatches={setNumMatches}
            onClick={() => handleClearFilter()}
          >
            <FormWrap>
              <DropdownWrapper ref={seasonDropdownRef}>
                <div
                  style={{ position: "relative" }}
                  onClick={() => setOpenSelectSeason(!openSelectSeason)}
                >
                  <TextInput
                    type="text"
                    value={initialSeasonTitle}
                    readOnly
                  />
                  <img
                    src={openSelectSeason ? arrowIconUp : arrowIconDown}
                    alt={arrowIconDown}
                    style={{
                      color: "#fff",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  />
                </div>
                {openSelectSeason && (
                  <OpenedDropdown>
                    {availableSeasons
                      ?.sort(
                        (a, b) => b?.year_season?.id - a?.year_season?.id
                      )
                      ?.map((item) => (
                        <div
                          key={item.id}
                          onClick={() => handleChangeSeason(item)}
                        >
                          {item.title}{" "}
                          {item?.year_season?.years
                            ? ` - ${item?.year_season?.years}`
                            : ""}
                        </div>
                      ))}
                  </OpenedDropdown>
                )}
              </DropdownWrapper>
              <DropdownWrapper
                ref={mathesCountDropdownRef}
              >
                <div
                  style={{ position: "relative" }}
                  onClick={() => setOpenMatchesCount(!openMatchesCount)}
                >
                  <TextInput
                    type="text"
                    value={
                      selectedMatch.length == 0 ? l(994) : selectedMatch
                    }
                    readOnly
                  />
                  <img
                    src={openMatchesCount ? arrowIconUp : arrowIconDown}
                    alt={arrowIconDown}
                    style={{
                      color: "#fff",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  />
                </div>
                {openMatchesCount && (
                  <OpenedDropdown>
                    {matches?.map((item, index) => (
                      <div key={index} onClick={() => handleMatches(item)}>
                        {item.title}
                      </div>
                    ))}
                  </OpenedDropdown>
                )}
              </DropdownWrapper>

              <ParamsWrap>
                <InputWrapper>
                  <input
                    type="text"
                    value={displayFilters()}
                    readOnly
                    style={{ width: "100%", marginBottom: "10px" }}
                    onClick={() => setOpenParams(!openParams)}
                  />
                  <img
                    className="dropdownIcon"
                    src={openParams ? arrowIconUp : arrowIconDown}
                    alt={openParams ? arrowIconUp : arrowIconDown}
                    onClick={() => setOpenParams(!openParams)}
                  />
                </InputWrapper>
              </ParamsWrap>
            </FormWrap>

            <Button
              size={"full"}
              sizeResponse={"full"}
              title={l(1014)}
              onClick={() => applyFilter()}
            />
          </Filter>
        </MatchTableWrap>

        <Pagination
          items={matchesCount}
          itemsPerPage={10}
          page={currentPage - 1 || 0}
          onPageChange={onPageChange}
        />
        {/* <PaginationWrapper className="pagination-controls">
          <PageButton onClick={handlePrevPage} disabled={currentPage === 1}>
            <img
              src={
                localStorage.getItem("theme") === "light"
                  ? arrowLight
                  : arrowDark
              }
              alt={arrowDark}
              className="leftTransform"
            />
          </PageButton>
          <span>
            Page {currentPage} of {totalPages.toLocaleString()}
          </span>
          <PageButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <img
              className="rightTransform"
              src={
                localStorage.getItem("theme") === "light"
                  ? arrowLight
                  : arrowDark
              }
              alt={arrowDark}
            />
          </PageButton>
        </PaginationWrapper> */}
      </>
      <ParametersSelector
        type={"team"}
        open={openParams}
        onClose={() => setOpenParams(false)}
        updateData={handleChangeParams}
      />

      <Formation
        open={openFormation}
        handleClose={handleCloseFormation}
        match={selectedFormationMatch}
      />
    </>
  );
};

export default CompetitionMatch;
